@import url("https://fonts.googleapis.com/css?family=Fira+Mono:400");

@keyframes fadeIn {
    0% {
        opacity: 0
    }
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(1.5rem)
    }
}

@keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translateY(-1.5rem)
    }
}

@keyframes fadeInLeft {
    0% {
        opacity: 0;
        transform: translateX(1.5rem)
    }
}

@keyframes fadeInRight {
    0% {
        opacity: 0;
        transform: translateX(-1.5rem)
    }
}

@keyframes flipIn {
    0% {
        opacity: 0;
        transform: scaleY(0)
    }
}

@keyframes blink {
    0%, 100% {
        opacity: 0
    }

    50% {
        opacity: 1
    }
}

@keyframes shake {
    15% {
        transform: translateX(0.5rem)
    }

    30% {
        transform: translateX(-0.4rem)
    }

    45% {
        transform: translateX(0.3rem)
    }

    60% {
        transform: translateX(-0.2rem)
    }

    75% {
        transform: translateX(0.1rem)
    }

    90% {
        transform: translateX(0)
    }

    90% {
        transform: translateX(0)
    }
}

@keyframes pulseBlue {
    0% {
        box-shadow: 0 0 0 0 rgba(0, 140, 255, 0.5)
    }

    70% {
        box-shadow: 0 0 0 0.5rem transparent
    }

    100% {
        box-shadow: 0 0 0 0 transparent
    }
}

.icon-dropdown {
    content: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%3E%3Cpath%20fill%3D%22%23008CFF%22%20d%3D%22M15%207l-6%206-6-6V6h1l5%205%205-5h1v1z%22%2F%3E%3C%2Fsvg%3E")
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-overflow-scrolling: touch
}

img {
    width: auto;
    max-width: 100%;
    display: block
}

svg {
    max-height: 100%
}

.fade-enter-active, .fade-leave-active {
    transition: opacity 150ms cubic-bezier(0, 0, 0.2, 1)
}

.fade-enter, .fade-leave-to {
    opacity: 0
}

.slide-down-enter-active, .slide-down-leave-active {
    transform: translateY(0);
    transition: opacity 100ms, transform 150ms;
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1)
}

.slide-down-enter, .slide-down-leave-to {
    transform: translateY(-1rem);
    opacity: 0
}

html {
    font-family: "Whitney SSm A", "Whitney SSm B", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
    font-size: inherit;
    font-style: normal;
    font-weight: inherit;
    color: inherit;
    text-decoration: none;
    font-size: 14px;
    color: #233659;
    text-size-adjust: 100%;
    -moz-text-size-adjust: 100%
}

@media screen and (min-width: 900px) {
    html {
        font-size: 15px
    }
}

@media screen and (min-width: 1400px) {
    html {
        font-size: 16px
    }
}

h1, h2, h3, h4, h5, h6 {
    font-size: 1rem;
    font-weight: normal
}

a, input {
    font-family: "Whitney SSm A", "Whitney SSm B", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
    font-size: inherit;
    font-style: normal;
    font-weight: inherit;
    color: inherit;
    text-decoration: none
}

pre, code {
    font-family: "Fira Mono", monospace;
    white-space: pre;
    tab-size: 2;
    font-size: 0.9rem;
    line-height: 1.6
}

::selection {
    background: #F22F46;
    color: white
}

*:active {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

.h1 {
    font-size: 3rem;
    line-height: 1.1
}

.h2 {
    font-size: 2.6rem;
    line-height: 1.15
}

.h3 {
    font-size: 2.2rem;
    line-height: 1.25
}

.h4 {
    font-size: 1.8rem;
    line-height: 1.35
}

.h5 {
    font-size: 1.4rem;
    line-height: 1.45;
    font-weight: 500
}

.h6 {
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.55
}

.h5-uppercase {
    font-size: 1.1rem;
    font-weight: 500;
    line-height: 1.6;
    text-transform: uppercase;
    letter-spacing: 0.1em
}

.h6-uppercase {
    font-size: 0.9rem;
    font-weight: 500;
    line-height: 1.6;
    text-transform: uppercase;
    letter-spacing: 0.1em
}

.bottom-rule:after {
    content: '';
    display: block;
    margin-top: 0.75em;
    margin-bottom: 0.75em;
    width: 2.5em;
    height: 4px;
    border-top: 4px solid #008CFF
}

.body-text-large {
    font-size: 1.2rem;
    line-height: 1.65
}

.body-text {
    font-size: 1rem;
    line-height: 1.65
}

.body-text-small {
    font-size: 0.9rem;
    line-height: 1.6
}

.fine-print {
    font-size: 0.75rem;
    line-height: 1.5;
    color: #94979B
}

.fine-print-uppercase {
    font-size: 0.75rem;
    line-height: 1.7;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    color: #94979B
}

.hanging-quotes {
    text-indent: -0.4em
}

.list-style-none {
    list-style: none
}

.list-inline-block {
    font-size: 0;
    list-style: none
}

.list-inline-block li {
    display: inline-block
}

.twlo-link {
    color: #008CFF;
    opacity: 0.999;
    text-decoration: underline
}

.twlo-link:hover, .twlo-link:visited {
    color: #0062b3
}

.twlo-link > svg {
    fill: currentColor
}

.twlo-link-btn {
    font-size: 0.9rem;
    line-height: 1.6;
    color: #008CFF;
    opacity: 0.999;
    display: inline-block;
    margin-right: 1rem;
    margin-bottom: 1rem;
    background-color: #f5f5f5;
    border-radius: 4px;
    padding: 0.5em 1em;
    transition: background 100ms
}

.twlo-link-btn:hover {
    color: #fff
}

.twlo-link-btn .twlo-icon-interface-link-out {
    margin-left: 0.5rem;
    transform: translateY(-1px)
}

.twlo-link-btn:hover {
    color: #fff;
    background: #008CFF
}

.twlo-tag {
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-size: 0.625rem;
    font-weight: 500;
    line-height: 1;
    display: inline-block;
    padding: 0.75em 1em;
    margin-right: 0.75em;
    margin-bottom: 0.75em;
    box-shadow: inset 0 0 0 1px rgba(148, 151, 155, 0.2);
    border-radius: 2px;
    transition: background-color 100ms;
    cursor: pointer
}

.twlo-tag:hover {
    background: white
}

.twlo-tag.is-selected {
    color: white;
    background-color: #008CFF;
    box-shadow: none;
    animation: pulseBlue 500ms
}

textarea {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: none;
    outline: none;
    font-size: inherit;
    font-family: inherit;
    padding: 1rem;
    height: 50px;
    width: 100%;
    border-radius: 4px;
    background-color: #F9F9F9;
    transition: box-shadow 200ms;
    box-shadow: inset 0 0 0 1px rgba(148, 151, 155, 0.2);
    resize: vertical
}

textarea:disabled {
    opacity: 0.5;
    cursor: not-allowed
}

/*textarea::placeholder {*/
    /*color: #94979B*/
/*}*/

textarea:focus {
    box-shadow: inset 0 0 0 1px #008CFF
}

select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: none;
    outline: none;
    font-size: inherit;
    font-family: inherit;
    padding: 1rem;
    height: 50px;
    width: 100%;
    border-radius: 4px;
    background-color: #F9F9F9;
    transition: box-shadow 200ms;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="8" height="11" viewBox="-469 273 8 11" enable-background="new -469 273 8 11"><path fill="%23B4B4B4" d="M-469 277l4-4 4 4zM-461 280l-4 4-4-4z"/></svg>');
    background-repeat: no-repeat;
    background-position: right 1.5rem center;
    height: 3rem;
    padding: 1rem;
    padding-right: 4rem;
    -webkit-padding-start: 1rem !important;
    -webkit-padding-end: 4rem !important
}

select:disabled {
    opacity: 0.5;
    cursor: not-allowed
}

/*select::placeholder {*/
    /*color: #94979B*/
/*}*/

pre {
    color: white;
    background-color: #233659;
    position: relative;
    width: 100%
}

pre code {
    display: block;
    overflow: auto;
    padding: 2rem
}

.token.operator, .token.entity, .token.url, .language-json .token.string {
    color: white
}

.token.comment, .token.prolog, .token.doctype, .token.cdata {
    color: #94979B
}

.language-markup .token.punctuation {
    color: #92BFBF
}

.token.tag, .token.boolean, .token.constant, .token.symbol {
    color: #B4D388
}

.token.property, .token.attr-value, .token.keyword, .token.string {
    color: #BDDCDC
}

.token.selector, .token.attr-name, .token.char, .token.builtin, .token.inserted, .token.number, .language-clike .token.boolean, .language-javascript .token.keyword, .language-js .token.keyword {
    color: #F9ED7B
}

.token.atrule, .token.regex, .token.variable, .token.function, .token.class-name, .token.null {
    color: #EA7956
}

.token.deleted, .token.important {
    color: #CF5340
}

.token.entity {
    cursor: help
}

.twlo-code.show-label::after {
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-size: 0.625rem;
    font-weight: 500;
    line-height: 1;
    padding: 4px 8px;
    position: absolute;
    user-select: none;
    top: 0;
    right: 0;
    color: #E8E8E8;
    background-color: #565B73;
    border-bottom-left-radius: 2px
}

.show-label.language-csharp::after {
    content: "C#"
}

.show-label.language-css::after {
    content: "CSS"
}

.show-label.language-dotnet::after {
    content: ".NET"
}

.show-label.language-html::after {
    content: "HTML"
}

.show-label.language-java::after {
    content: "Java"
}

.show-label.language-javascript::after {
    content: "Node"
}

.show-label.language-markup::after {
    content: "Markup"
}

.show-label.language-php::after {
    content: "PHP"
}

.show-label.language-python::after {
    content: "Python"
}

.show-label.language-ruby::after {
    content: "Ruby"
}

.show-label.language-scss::after {
    content: "SCSS"
}

.show-label.language-bash::after {
    content: "Shell"
}

.show-label.language-swift::after {
    content: "Swift"
}

.twlo-code.syntax-theme-light.show-label::after {
    color: #94979B;
    background-color: #E8E8E8
}

.twlo-code.syntax-theme-light pre, .twlo-code.syntax-theme-light .twlo-code__sample {
    color: #565B73;
    background-color: #F9F9F9
}

.twlo-code.syntax-theme-light .line-highlight {
    box-shadow: inset 0 1px 0 0 #F9F9F9
}

.twlo-code.syntax-theme-light .twlo-code__action {
    color: #233659;
    background: #E8E8E8
}

.syntax-theme-light .token.operator, .syntax-theme-light .token.entity, .syntax-theme-light .token.url, .syntax-theme-light .language-json .token.string {
    color: #565B73
}

.syntax-theme-light .token.comment, .syntax-theme-light .token.prolog, .syntax-theme-light .token.doctype, .syntax-theme-light .token.cdata {
    color: #B9B9B9
}

.syntax-theme-light .token.property, .syntax-theme-light .token.attr-value, .syntax-theme-light .token.keyword, .syntax-theme-light .token.string {
    color: #6EA2A2
}

.syntax-theme-light .token.tag, .syntax-theme-light .token.boolean, .syntax-theme-light .token.constant, .syntax-theme-light .token.symbol, .syntax-theme-light .token.selector, .syntax-theme-light .token.attr-name, .syntax-theme-light .token.char, .syntax-theme-light .token.builtin, .syntax-theme-light .token.inserted, .syntax-theme-light .token.number, .syntax-theme-light .language-clike .token.boolean, .syntax-theme-light .language-javascript .token.keyword, .syntax-theme-light .language-js .token.keyword {
    color: #86AB52
}

.syntax-theme-light .token.atrule, .syntax-theme-light .token.regex, .syntax-theme-light .token.variable, .syntax-theme-light .token.function, .syntax-theme-light .token.class-name, .syntax-theme-light .token.null {
    color: #EA7956
}

.syntax-theme-light .token.deleted, .syntax-theme-light .token.important {
    color: #CF5340
}

.code-silver {
    color: #B9B9B9
}

.code-grey {
    color: #94979B
}

.code-dark-grey {
    color: #565B73
}

.code-blue {
    color: #92BFBF
}

.code-light-blue {
    color: #BDDCDC
}

.code-dark-blue {
    color: #6EA2A2
}

.code-green {
    color: #B4D388
}

.code-dark-green {
    color: #86AB52
}

.code-yellow {
    color: #F9ED7B
}

.code-dark-yellow {
    color: #F9ED7B
}

.code-orange {
    color: #EA7956
}

.code-red {
    color: #CF5340
}

.twlo-shadow-small {
    box-shadow: 0 4px 24px 0 rgba(86, 91, 115, 0.15)
}

.twlo-shadow-medium {
    box-shadow: 0 8px 48px 4px rgba(86, 91, 115, 0.15)
}

.twlo-shadow-large {
    box-shadow: 0 16px 80px 8px rgba(86, 91, 115, 0.15)
}

.twlo-drop-shadow-small {
    box-shadow: 0 4px 8px 0 rgba(86, 91, 115, 0.15)
}

.twlo-drop-shadow-medium {
    filter: drop-shadow(0 8px 16px rgba(86, 91, 115, 0.25))
}

.twlo-drop-shadow-large {
    filter: drop-shadow(0 8px 32px rgba(86, 91, 115, 0.25))
}

.twlo-btn {
    font-family: inherit;
    text-decoration: none;
    text-shadow: none;
    appearance: none;
    border: none;
    outline: none;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 64px;
    max-width: 100%;
    padding-left: 2em;
    padding-right: 2em;
    border-radius: 4px;
    flex-shrink: 0;
    min-width: min-content;
    backface-visibility: hidden;
    font-size: 1rem;
    line-height: 1.65;
    color: #fff;
    background-color: #008CFF;
    box-shadow: 0 8px 48px 4px rgba(86, 91, 115, 0.15);
    will-change: background-color, transform, box-shadow;
    transition-property: background-color, transform, box-shadow;
    transition-duration: 200ms;
    transition-property: all;
    transition-duration: 200ms
}

.twlo-btn:hover {
    transform: translateY(-2px);
    box-shadow: 0 16px 60px 0 rgba(86, 91, 115, 0.2)
}

.twlo-btn:active {
    transition: none;
    transform: translateY(-1px)
}

.twlo-btn:hover {
    color: #fff;
    background-color: #0f93ff
}

.twlo-btn:disabled, .twlo-btn.disabled {
    opacity: 0.5;
    cursor: not-allowed
}

@media screen and (min-width: 480px) {
    .twlo-btn {
        display: inline-flex
    }
}

.twlo-btn-red {
    font-family: inherit;
    text-decoration: none;
    text-shadow: none;
    appearance: none;
    border: none;
    outline: none;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 64px;
    max-width: 100%;
    padding-left: 2em;
    padding-right: 2em;
    border-radius: 4px;
    flex-shrink: 0;
    min-width: min-content;
    backface-visibility: hidden;
    font-size: 1rem;
    line-height: 1.65;
    color: #fff;
    background-color: #F22F46;
    box-shadow: 0 8px 48px 4px rgba(86, 91, 115, 0.15);
    will-change: background-color, transform, box-shadow;
    transition-property: background-color, transform, box-shadow;
    transition-duration: 200ms;
    transition-property: all;
    transition-duration: 200ms
}

.twlo-btn-red:hover {
    transform: translateY(-2px);
    box-shadow: 0 16px 60px 0 rgba(86, 91, 115, 0.2)
}

.twlo-btn-red:active {
    transition: none;
    transform: translateY(-1px)
}

.twlo-btn-red:hover {
    color: #fff;
    background-color: #f33d53
}

.twlo-btn-red:disabled, .twlo-btn-red.disabled {
    opacity: 0.5;
    cursor: not-allowed
}

@media screen and (min-width: 480px) {
    .twlo-btn-red {
        display: inline-flex
    }
}

.twlo-btn-white {
    font-family: inherit;
    text-decoration: none;
    text-shadow: none;
    appearance: none;
    border: none;
    outline: none;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 64px;
    max-width: 100%;
    padding-left: 2em;
    padding-right: 2em;
    border-radius: 4px;
    flex-shrink: 0;
    min-width: min-content;
    backface-visibility: hidden;
    font-size: 1rem;
    line-height: 1.65;
    color: #565B73;
    background-color: #fff;
    box-shadow: 0 8px 48px 4px rgba(86, 91, 115, 0.15);
    will-change: background-color, transform, box-shadow;
    transition-property: background-color, transform, box-shadow;
    transition-duration: 200ms;
    transition-property: all;
    transition-duration: 200ms;
    color: #565B73
}

.twlo-btn-white:hover {
    transform: translateY(-2px);
    box-shadow: 0 16px 60px 0 rgba(86, 91, 115, 0.2)
}

.twlo-btn-white:active {
    transition: none;
    transform: translateY(-1px)
}

.twlo-btn-white:hover {
    color: #565B73;
    background-color: #fff
}

.twlo-btn-white:disabled, .twlo-btn-white.disabled {
    opacity: 0.5;
    cursor: not-allowed
}

@media screen and (min-width: 480px) {
    .twlo-btn-white {
        display: inline-flex
    }
}

.twlo-btn-group {
    display: inline-block
}

.twlo-btn__cursor-arrow {
    margin-left: 2ch
}

.twlo-btn__cursor-arrow::before {
    content: '';
    border: solid white;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 4px;
    transform: rotate(-45deg) translateY(-1px);
    transition: transform 200ms cubic-bezier(0, 0, 0.2, 1)
}

.twlo-btn__cursor-arrow::after {
    content: '';
    display: inline-block;
    background-color: white;
    height: 2px;
    width: 10px;
    margin-left: 0.25em;
    animation: blink 1.1s infinite;
    transition: transform 200ms cubic-bezier(0, 0, 0.2, 1)
}

.cursor-hover:hover .twlo-btn__cursor-arrow::before {
    transform: rotate(-45deg) translateY(8px) translateX(8px)
}

.cursor-hover:hover .twlo-btn__cursor-arrow::after {
    transform: translateX(-6px) translateY(-4px) scaleX(1.5);
    animation: none
}

@media screen and (max-width: 899px) {
    .twlo-btn__cursor-arrow::before {
        transform: rotate(-45deg) translateY(8px) translateX(8px)
    }

    .twlo-btn__cursor-arrow::after {
        transform: translateX(-6px) translateY(-4px) scaleX(1.5);
        animation: none
    }
}

.twlo-btn-group__secondary-action {
    font-size: 1rem;
    line-height: 1.65;
    display: block;
    padding-top: 1em;
    text-align: center
}

.twlo-btn-group__secondary-action a {
    font-family: inherit;
    text-decoration: none;
    text-shadow: none;
    appearance: none;
    border: none;
    outline: none;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    text-decoration: underline;
    text-decoration-color: #F22F46;
    white-space: nowrap
}

.twlo-btn-group__secondary-action a:hover {
    color: #F22F46
}

.docs-article__next_step .twlo-btn {
    width: 100%;
    white-space: normal;
    text-align: center
}

.docs-article__next_step .twlo-btn__cursor-arrow {
    width: 2.5em
}

.twlo-card {
    background: white;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0 8px 48px 4px rgba(86, 91, 115, 0.15)
}

@media screen and (min-width: 900px) {
    .twlo-card {
        align-self: stretch
    }
}

.twlo-card__header {
    background-color: #008CFF;
    padding: 1rem 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between
}

.twlo-card__title {
    color: white;
    white-space: nowrap;
    font-size: 1rem;
    line-height: 1.65
}

.twlo-card__header-link {
    font-size: 0.75rem;
    line-height: 1.7;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    color: #94979B;
    color: #fff;
    background: rgba(255, 255, 255, 0.15);
    line-height: 0;
    padding: 0.75em 1em;
    border-radius: 2px;
    white-space: nowrap;
    transition: background 200ms
}

.twlo-card__header-link svg, .twlo-card__header-link::after {
    fill: currentColor;
    margin-left: 0.5rem
}

.twlo-card__header-link::after {
    content: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%228%22%20height%3D%228%22%20viewBox%3D%220%200%208%208%22%3E%3Cpath%20fill%3D%22%23fff%22%20d%3D%22M8%208H7V1.7L.9%207.9l-.8-.8L6.3%201H0V0h8v8z%22%2F%3E%3C%2Fsvg%3E")
}

.twlo-card__header-link:hover {
    background: rgba(255, 255, 255, 0.25)
}

.twlo-card__content {
    padding: 1.5rem
}

.twlo-card__subtitle {
    font-size: 0.9rem;
    line-height: 1.6;
    margin-bottom: 1.5rem;
    margin-bottom: 1.5rem
}

.twlo-card__link-btns {
    font-size: 0;
    list-style: none
}

.twlo-card__link-btns li {
    display: inline-block
}

.twlo-card__links {
    list-style: none
}

.twlo-card__links li {
    margin-bottom: 1rem
}

@keyframes rotate {
    100% {
        transform: rotate(360deg)
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0
    }

    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35
    }

    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124
    }
}

.twlo-loading {
    position: absolute;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #233659;
    z-index: 30
}

.twlo-loading__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    animation: fadeInUp 200ms cubic-bezier(0, 0, 0.2, 1)
}

.twlo-loading__spinner {
    width: 50px;
    height: 50px
}

.twlo-loading__spinner svg {
    position: relative;
    animation: rotate 2s linear infinite;
    height: 50px;
    width: 50px
}

.twlo-loading__spinner circle {
    stroke: #F22F46;
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite
}

.twlo-laoding__message {
    font-family: "Fira Mono", monospace;
    font-size: 0.9rem;
    line-height: 1.6;
    font-size: 0.75rem;
    line-height: 1.7;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    color: #94979B;
    color: #94979B;
    margin-top: 2rem;
    white-space: normal
}

.twlo-loading.loading-theme-light {
    background: #F9F9F9
}

.twlo-loading.loading-no-bg {
    background: transparent
}

.syntax-theme-light .twlo-loading {
    background: #F9F9F9
}

.twlo-code {
    margin-bottom: 2rem;
    position: relative;
    z-index: 1;
    border-radius: 4px;
    overflow: auto
}

.twlo-code:not(.code-rail) {
    padding: 2em
}

.twlo-code__sample, .twlo-code__output {
    position: relative;
    background: #233659;
    overflow-x: auto;
    text-align: left;
    z-index: 1
}

.twlo-code__snippet {
    padding: 1.5rem;
    min-width: max-content;
    background-color: transparent;
    transition-property: opacity, visibility;
    transition-duration: 200ms
}

.twlo-code__snippet pre, .twlo-code__snippet code {
    overflow: visible;
    display: initial;
    padding: 0
}

.twlo-code__snippet.is-loading {
    opacity: 0;
    visibility: hidden
}

.twlo-code__snippet.is-visible {
    display: block !important
}

.twlo-code pre.line-numbers {
    padding-left: 4.5rem !important;
    counter-reset: linenumber
}

.twlo-code pre.line-numbers > code {
    position: relative
}

.twlo-code pre.line-numbers .line-numbers-rows {
    position: absolute;
    pointer-events: none;
    top: 0;
    left: -4.5rem;
    width: 3rem;
    user-select: none
}

.twlo-code .line-numbers-rows > span {
    pointer-events: none;
    display: block;
    counter-increment: linenumber;
    transform: translateY(-0.1em)
}

.twlo-code .line-numbers-rows > span:before {
    content: counter(linenumber);
    color: rgba(148, 151, 155, 0.5);
    display: block;
    text-align: right
}

.twlo-code pre[data-line] {
    position: relative
}

.twlo-code .line-highlight {
    margin-top: 1.5rem;
    position: absolute;
    left: 0;
    right: 0;
    background: rgba(148, 151, 155, 0.1);
    box-shadow: inset 0 1px 0 0 #233659;
    line-height: inherit;
    white-space: pre;
    display: flex;
    align-items: center
}

.twlo-code .line-highlight::before {
    content: ' ';
    background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%3E%3Cpath%20fill%3D%22%23F22F46%22%20d%3D%22M15.8%207.6c-.1-.2-3.5-4.7-7.8-4.7S.3%207.4.2%207.6c-.2.3-.2.6%200%20.9.1.2%203.5%204.6%207.8%204.6s7.7-4.4%207.8-4.6c.3-.4.3-.6%200-.9zm-7.8%204C6%2011.6%204.4%2010%204.4%208S6%204.4%208%204.4%2011.6%206%2011.6%208%2010%2011.6%208%2011.6zM9.8%208C9.8%209%209%209.8%208%209.8S6.2%209%206.2%208%207%206.2%208%206.2%209.8%207%209.8%208z%22%2F%3E%3C%2Fsvg%3E");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    height: 14px;
    width: 14px;
    opacity: 0.75;
    margin-left: 0.65rem;
    cursor: pointer;
    transition: opacity 100ms
}

.twlo-code .line-highlight:hover::before {
    opacity: 1
}

.twlo-code .line-highlight:hover::after {
    font-family: "Whitney SSm A", "Whitney SSm B", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
    font-size: 0.75rem;
    line-height: 1.5;
    color: #94979B;
    content: 'Log in to autofill your credentials.';
    text-decoration: underline;
    text-decoration-color: rgba(255, 255, 255, 0.75);
    color: white;
    background: #F22F46;
    padding: 4px 8px;
    border-radius: 2px;
    margin-left: 0.75rem;
    animation: fadeInRight 200ms;
    cursor: pointer;
    z-index: 10
}

.twlo-code__output {
    border-top: 1px solid rgba(148, 151, 155, 0.2)
}

.twlo-code__output .twlo-code__snippet {
    word-break: break-all
}

.twlo-code__actions {
    position: sticky;
    left: 0;
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
    margin-right: 1rem;
    margin-bottom: -1rem;
    z-index: 2
}

@media screen and (min-width: 1200px) {
    .twlo-code__actions {
        margin-bottom: -2.5rem
    }
}

.twlo-code__action {
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #152748;
    border-radius: 4px;
    height: 2rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    min-width: 2rem;
    cursor: pointer
}

.twlo-code__action:not(:last-child) {
    margin-right: 0.25rem
}

.twlo-code__action .twlo-icon-interface-clipboard, .twlo-code__action .twlo-icon-interface-repo {
    transition: opacity 100ms;
    opacity: 0.4
}

.twlo-code__action.clippy:hover::after {
    content: 'Click to copy';
    font-size: 0.75rem;
    line-height: 1.5;
    color: #94979B;
    font-family: "Whitney SSm A", "Whitney SSm B", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
    text-decoration: underline;
    text-decoration-color: rgba(255, 255, 255, 0.75);
    color: white;
    background: #F22F46;
    padding: 4px 8px;
    border-radius: 2px;
    margin-left: 0.75rem;
    animation: fadeInRight 200ms;
    cursor: pointer;
    z-index: 10;
    position: absolute;
    top: 3em;
    right: 0em;
    text-decoration: none
}

.twlo-code__action.github:hover::after {
    content: 'Open code sample on Github';
    font-size: 0.75rem;
    line-height: 1.5;
    color: #94979B;
    font-family: "Whitney SSm A", "Whitney SSm B", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
    text-decoration: underline;
    text-decoration-color: rgba(255, 255, 255, 0.75);
    color: white;
    background: #F22F46;
    padding: 4px 8px;
    border-radius: 2px;
    margin-left: 0.75rem;
    animation: fadeInRight 200ms;
    cursor: pointer;
    z-index: 10;
    position: absolute;
    top: 3em;
    right: 0em;
    text-decoration: none
}

.twlo-code__action:hover .twlo-icon-interface-clipboard, .twlo-code__action:hover .twlo-icon-interface-repo {
    opacity: 1
}

.twlo-code__action-label {
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-size: 0.625rem;
    font-weight: 500;
    line-height: 1;
    margin-left: 0.25rem;
    margin-right: 0.5rem;
    animation: fadeInLeft 200ms cubic-bezier(0.4, 0, 0.2, 1)
}

.twlo-code-header {
    background-color: #152748;
    box-shadow: inset 0 -2px 0 -1px rgba(148, 151, 155, 0.2);
    min-height: 8rem;
    position: sticky;
    top: 0;
    z-index: 10;
    color: white
}

.twlo-code-header__actions {
    height: 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: inset 0 2px 0 -1px rgba(148, 151, 155, 0.2);
    overflow-x: auto;
    user-select: none
}

.twlo-code-header__actions::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: transparent
}

@keyframes loadingPulse {
    0%, 100% {
        opacity: 0.1
    }

    50% {
        opacity: 0.15
    }
}

.twlo-code-header__title {
    display: flex;
    align-items: center;
    position: relative;
    height: 5rem;
    padding: 1.5rem;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.55;
    line-height: 1.2
}

.twlo-code-header__title:hover {
    text-decoration: underline
}

.twlo-code-header__title .twlo-code-header__title-inner {
    animation: fadeInUp 100ms cubic-bezier(0, 0, 0.2, 1)
}

.twlo-code-header__title .twlo-code-header__title-loading {
    display: none;
    animation: loadingPulse 500ms cubic-bezier(0.4, 0, 0.2, 1) infinite, fadeInUp 100ms cubic-bezier(0, 0, 0.2, 1);
    height: 0.5rem;
    width: 40%;
    background: #94979B;
    opacity: 0.2;
    border-radius: 4px;
    margin-top: -1rem
}

.twlo-code-header__title .twlo-code-header__title-loading::after {
    content: '';
    display: block;
    background: #94979B;
    margin-top: 1rem;
    height: 0.5rem;
    width: 75%;
    border-radius: 4px
}

.twlo-code-header__title.is-loading .twlo-code-header__title-inner {
    display: none
}

.twlo-code-header__title.is-loading .twlo-code-header__title-loading {
    display: block
}

.twlo-code-header__title.is-loading::after {
    display: none
}

a.twlo-code-header__title::after {
    content: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%3E%3Cpath%20fill%3D%22rgba%28148%2C151%2C155%2C0.75%29%22%20d%3D%22M3%2010L1%208V4l2-2h7l2%202v4l-2%202H7V9h2.5L11%207.5v-3L9.5%203h-6L2%204.5v3l1%201V10zm10-4l2%202v4l-2%202H6l-2-2V8l2-2h3v1H6.5L5%208.5v3L6.5%2013h6l1.5-1.5v-3l-1-1V6z%22%2F%3E%3C%2Fsvg%3E");
    display: inline-block;
    height: 16px;
    width: 16px;
    margin-left: 1rem
}

.twlo-code-header__languages {
    list-style: none;
    display: flex;
    align-items: center;
    align-self: stretch;
    padding-left: 1.5rem
}

.twlo-code-header__language {
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-size: 0.625rem;
    font-weight: 500;
    line-height: 1;
    opacity: 0.5;
    display: flex;
    align-items: center;
    align-self: stretch;
    margin-right: 1.5rem;
    cursor: default;
    transition: box-shadow 150ms
}

.twlo-code-header__language:hover {
    opacity: 1
}

.twlo-code-header__language.is-selected {
    opacity: 1;
    box-shadow: inset 0px -2px 0 #008CFF;
    cursor: default
}

.twlo-code-header__actions.languages-only {
    box-shadow: none
}

.twlo-code-header__actions.languages-only .twlo-code-header__language {
    color: #565B73
}

@media screen and (min-width: 480px) {
    .twlo-code-header__actions.languages-only + .twlo-code__sample {
        border-radius: 4px
    }
}

.twlo-code-header__versions {
    display: flex;
    list-style: none
}

.twlo-code-header__version {
    margin-right: 1rem;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-size: 0.625rem;
    font-weight: 500;
    line-height: 1;
    opacity: 0.5;
    cursor: pointer
}

.twlo-code-header__version:hover {
    opacity: 1
}

.twlo-code-header__version.is-selected {
    opacity: 1;
    cursor: default
}

.twlo-code-header__alert {
    font-size: 0.75rem;
    line-height: 1.5;
    color: #94979B;
    color: white;
    background-color: rgba(242, 47, 70, 0.6);
    padding-left: 1rem;
    padding-right: 1rem;
    height: 2rem;
    display: flex;
    align-items: center;
    user-select: none;
    animation: fadeIn 100ms;
    white-space: nowrap
}

.syntax-theme-light .twlo-code-header {
    background-color: #f5f5f5;
    color: #565B73
}

.syntax-theme-light .twlo-code__code {
    background: #F9F9F9
}

.twlo-product {
    min-width: 320px;
    max-width: 320px;
    margin-bottom: 10%;
    animation: fadeInUp 200ms backwards
}

.twlo-product:nth-of-type(1) {
    animation-delay: 25ms
}

.twlo-product:nth-of-type(2) {
    animation-delay: 50ms
}

.twlo-product:nth-of-type(3) {
    animation-delay: 75ms
}

.twlo-product:nth-of-type(4) {
    animation-delay: 100ms
}

.twlo-product:nth-of-type(5) {
    animation-delay: 125ms
}

.twlo-product:nth-of-type(6) {
    animation-delay: 150ms
}

.twlo-product:nth-of-type(7) {
    animation-delay: 175ms
}

.twlo-product:nth-of-type(8) {
    animation-delay: 200ms
}

.twlo-product:nth-of-type(9) {
    animation-delay: 225ms
}

.twlo-product:nth-of-type(10) {
    animation-delay: 250ms
}

.twlo-product:nth-of-type(11) {
    animation-delay: 275ms
}

.twlo-product:nth-of-type(12) {
    animation-delay: 300ms
}

.twlo-product:nth-of-type(13) {
    animation-delay: 325ms
}

.twlo-product:nth-of-type(14) {
    animation-delay: 350ms
}

.twlo-product:nth-of-type(15) {
    animation-delay: 375ms
}

.twlo-product:nth-of-type(16) {
    animation-delay: 400ms
}

.twlo-product:nth-of-type(17) {
    animation-delay: 425ms
}

.twlo-product:nth-of-type(18) {
    animation-delay: 450ms
}

.twlo-product:nth-of-type(19) {
    animation-delay: 475ms
}

.twlo-product:nth-of-type(20) {
    animation-delay: 500ms
}

.twlo-product:nth-of-type(21) {
    animation-delay: 525ms
}

.twlo-product:nth-of-type(22) {
    animation-delay: 550ms
}

.twlo-product:nth-of-type(23) {
    animation-delay: 575ms
}

.twlo-product:nth-of-type(24) {
    animation-delay: 600ms
}

.twlo-product__inner {
    display: flex;
    align-items: center
}

.twlo-product__link:hover .twlo-product__icon {
    color: white;
    background: #008CFF;
    transform: translateY(-2px);
    box-shadow: 0 2px 10px rgba(86, 91, 115, 0.3)
}

.twlo-product__icon {
    color: #008CFF;
    background: rgba(0, 140, 255, 0.05);
    height: 64px;
    width: 64px;
    border-radius: 100%;
    display: flex;
    flex: none;
    align-self: flex-start;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
    transition: color 100ms, background 200ms, transform 200ms, box-shadow 200ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
}

.twlo-product__icon .twlo-icon-product-channels, .twlo-product__icon .twlo-icon-product-chat, .twlo-product__icon .twlo-icon-product-fax, .twlo-product__icon .twlo-icon-product-notify, .twlo-product__icon .twlo-icon-product-short-codes, .twlo-product__icon .twlo-icon-product-sms, .twlo-product__icon .twlo-icon-product-verify {
    transform: translateY(2px)
}

.twlo-product__title {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.5;
    margin-bottom: 0
}

.twlo-product__badge {
    color: white;
    background: #008CFF;
    border-radius: 10px;
    font-size: 11px;
    font-weight: 400;
    padding: 3px 5px;
    display: inline-block;
    line-height: 1;
    vertical-align: 4px;
    margin: 0 -23px 0 2px
}

.twlo-product__subtitle {
    font-size: 0.9rem;
    line-height: 1.6;
    color: #94979B;
    line-height: 1.5;
    max-width: 30ch;
    margin: 0
}

.twlo-product-diagram {
    position: relative;
    max-width: 700px;
    height: 25rem;
    margin: 1rem;
    margin-top: 2rem;
    margin-bottom: 6rem;
    z-index: -1
}

.twlo-product-diagram__label {
    font-size: 0.75rem;
    line-height: 1.7;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    color: #94979B;
    position: absolute
}

@keyframes pulseRed {
    0% {
        box-shadow: 0 0 0 0 rgba(242, 47, 70, 0.4)
    }

    70% {
        box-shadow: 0 0 0 1rem transparent
    }

    100% {
        box-shadow: 0 0 0 0 transparent
    }
}

.twlo-product-diagram__overlay {
    position: absolute;
    cursor: default;
    z-index: 40
}

.twlo-product-diagram__overlay-number {
    background-color: rgba(242, 47, 70, 0.3);
    color: white;
    height: 3rem;
    width: 3rem;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 300ms;
    animation: pulseRed 3s infinite
}

.twlo-product-diagram__overlay-number:hover {
    animation: none
}

.twlo-product-diagram__overlay-number-inner {
    background-color: rgba(242, 47, 70, 0.6);
    height: 2rem;
    width: 2rem;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 300ms
}

.twlo-product-diagram__overlay-body {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    pointer-events: none;
    bottom: 0;
    width: 16rem;
    font-size: 0.75rem;
    line-height: 1.5;
    color: #94979B;
    background-color: #F22F46;
    color: white;
    border-radius: 4px;
    padding: 1rem;
    transition: opacity 200ms, visibility 200ms, transform 300ms cubic-bezier(0, 0, 0.2, 1)
}

.twlo-product-diagram__overlay:hover .twlo-product-diagram__overlay-body {
    opacity: 1;
    visibility: visible;
    transform: translateY(-3.5rem)
}

.twlo-product-diagram__overlay:hover .twlo-product-diagram__overlay-number {
    background-color: rgba(242, 47, 70, 0.6)
}

.twlo-product-diagram__overlay:hover .twlo-product-diagram__overlay-number-inner {
    background-color: #F22F46
}

.twlo-product-diagram__step-1 {
    position: absolute;
    right: 0;
    background-color: #565B73;
    height: 192px;
    width: 80%;
    border-radius: 4px;
    z-index: 10;
    animation: fadeIn 500ms cubic-bezier(0.4, 0, 0.2, 1) backwards
}

.twlo-product-diagram__step-1 .twlo-product-diagram__label {
    top: 0;
    right: 0;
    background-color: #565B73;
    padding: 1rem;
    padding-bottom: 0.5rem;
    z-index: 1
}

.twlo-product-diagram__step-1 .twlo-product-diagram__overlay {
    top: -1.5rem;
    right: -1.5rem
}

.twlo-product-diagram__step-1 .twlo-product-diagram__overlay-body {
    right: 0
}

@media screen and (min-width: 480px) {
    .twlo-product-diagram__step-1 .twlo-product-diagram__overlay-body {
        width: 24rem
    }
}

.twlo-product-diagram__step-1-code {
    overflow: hidden;
    padding: 1rem;
    opacity: 0.25
}

.twlo-product-diagram__step-2 {
    position: relative;
    width: 90%;
    background-color: #233659;
    box-shadow: 0 16px 80px 8px rgba(86, 91, 115, 0.15);
    border-radius: 4px;
    top: 4rem;
    bottom: 1rem;
    padding: 2rem;
    z-index: 20;
    animation: fadeIn 500ms 200ms cubic-bezier(0.4, 0, 0.2, 1) backwards
}

.twlo-product-diagram__step-2 .twlo-product-diagram__label {
    top: 1rem;
    right: 1rem
}

.twlo-product-diagram__step-2 .twlo-product-diagram__overlay {
    top: -1.5em;
    left: -1.5em
}

.twlo-product-diagram__step-2-code {
    font-family: "Fira Mono", monospace;
    font-size: 0.9rem;
    line-height: 1.6;
    color: #E8E8E8;
    line-height: 1.8;
    overflow: auto;
    margin-bottom: 1rem
}

.twlo-product-diagram__step-2-code code {
    padding: 0
}

.twlo-product-diagram__step-2-link {
    font-size: 0.75rem;
    line-height: 1.5;
    color: #94979B
}

.twlo-product-diagram__step-2-link:hover {
    color: #94979B;
    text-decoration: underline
}

.twlo-product-diagram__step-2-link > svg {
    fill: currentColor;
    margin-left: 0.5rem
}

.twlo-product-diagram__step-3 {
    position: relative;
    right: 0rem;
    bottom: -3rem;
    z-index: 30;
    animation: fadeIn 500ms 400ms cubic-bezier(0.4, 0, 0.2, 1) backwards;
    width: 60%;
    margin-left: auto
}

.twlo-product-diagram__step-3 .twlo-product-diagram__overlay {
    top: -1.5rem;
    right: -1.5rem
}

.twlo-product-diagram__step-3 .twlo-product-diagram__overlay-body {
    right: 0
}

.twlo-product-diagram__step-3 .twlo-product-diagram__label {
    right: 0
}

.twlo-product-diagram__chat-bubble {
    background-color: white;
    padding: 1rem 1.5rem;
    font-size: 1rem;
    line-height: 1.65;
    border-radius: 4px;
    border-bottom-right-radius: 0px;
    box-shadow: 0 8px 48px 4px rgba(86, 91, 115, 0.15);
    margin-bottom: 1rem;
    position: relative
}

.twlo-product-diagram__chat-bubble::after {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 1.5rem 0.5rem 0;
    border-color: transparent #fff transparent transparent;
    right: 0;
    bottom: -0.5rem
}

.twlo-diagram-code-editor-phone {
    position: relative;
    width: 440px;
    height: 250px;
    max-width: 100%;
    margin: 0 auto
}

.twlo-diagram-code-editor {
    filter: drop-shadow(0 8px 16px rgba(86, 91, 115, 0.25));
    position: absolute;
    left: 0;
    width: calc(100% - 24px)
}

@media screen and (min-width: 500px) {
    .twlo-diagram-code-editor {
        max-width: 100%
    }
}

.twlo-diagram-phone {
    filter: drop-shadow(0 8px 16px rgba(86, 91, 115, 0.25));
    position: absolute;
    right: 0;
    bottom: 32px;
    height: 25vw
}

@media screen and (min-width: 400px) {
    .twlo-diagram-phone {
        height: auto;
        bottom: 0
    }
}

.twlo-diagram-server-dashboard-status {
    position: relative;
    width: 400px;
    height: 310px;
    max-width: 100%;
    margin: 0 auto
}

.twlo-diagram-server {
    position: absolute;
    max-width: 90%;
    left: 0
}

.twlo-diagram-dashboard {
    filter: drop-shadow(0 8px 16px rgba(86, 91, 115, 0.25));
    position: absolute;
    top: 32px;
    right: 0;
    max-width: 90%
}

.twlo-diagram-status {
    background-color: white;
    padding: 16px;
    height: 56px;
    width: 56px;
    border-radius: 50%;
    filter: drop-shadow(0 8px 16px rgba(86, 91, 115, 0.25));
    position: absolute;
    z-index: 10;
    right: 8px;
    bottom: 40px
}

@media screen and (min-width: 400px) {
    .twlo-diagram-status {
        right: -32px;
        bottom: 64px
    }
}

.twlo-diagram-status-label {
    position: absolute;
    right: 0;
    bottom: 8px;
    opacity: 0.99;
    font-size: 0.75rem;
    line-height: 1.7;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    color: #94979B;
    color: #36D576
}

@media screen and (min-width: 400px) {
    .twlo-diagram-status-label {
        bottom: 0
    }
}

.twlo-footer {
    color: white;
    background: #233659;
    padding: 4rem;
    text-align: center
}

@media screen and (min-width: 1200px) {
    .twlo-footer {
        padding: 5rem
    }
}

.twlo-footer footer {
    padding: 0
}

.twlo-footer__logo {
    color: #F22F46;
    height: 32px;
    width: 32px;
    display: inline-block;
    margin-bottom: 1rem
}

.twlo-footer__list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    list-style: none;
    margin-top: 1rem
}

@media screen and (min-width: 830px) {
    .twlo-footer__list {
        flex-direction: row
    }
}

.twlo-footer__list-item {
    display: flex;
    color: rgba(255, 255, 255, 0.3);
    letter-spacing: 0.15em;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 400;
    white-space: nowrap;
    position: relative
}

@media screen and (max-width: 829px) {
    .twlo-footer__list-item:not(:last-child) {
        margin-bottom: 0.5rem
    }
}

.twlo-footer__list-item:not(:last-child)::after {
    content: "";
    display: none;
    align-self: center;
    height: 10px;
    margin-left: 15px;
    margin-right: 15px;
    border-right: 1px solid rgba(86, 91, 115, 0.65)
}

@media screen and (min-width: 830px) {
    .twlo-footer__list-item:not(:last-child)::after {
        display: flex
    }
}

.twlo-footer__link {
    color: inherit;
    cursor: pointer
}

.twlo-footer__link:hover {
    color: #94979B
}

.twlo-video-overlay-container {
    position: relative
}

.twlo-video-overlay {
    position: absolute;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    pointer-events: none;
    z-index: 1;
    color: white;
    background: linear-gradient(to bottom right, rgba(0, 140, 255, 0.25), rgba(242, 47, 70, 0.25));
    opacity: 1;
    visibility: visible;
    transition: opacity 200ms, visibility 200ms
}

.twlo-video-overlay .twlo-icon-interface-play {
    position: absolute;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    transform: translateY(-0.65rem)
}

.twlo-video-info {
    display: none;
    position: absolute;
    bottom: 10%;
    left: 5%
}

@media screen and (min-width: 480px) {
    .twlo-video-info {
        display: block
    }
}

.twlo-video-title {
    font-size: 1.8rem;
    line-height: 1.35;
    max-width: 25ch;
    margin-bottom: 1rem
}

.twlo-video-duration-container {
    font-size: 0.9rem;
    line-height: 1.6;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    color: #94979B
}

.twlo-video-duration {
    color: white
}

.twlo-video-overlay {
    opacity: 0;
    visibility: hidden
}

.twlo-video-title, .twlo-video-duration-container {
    opacity: 0;
    transform: translateY(3rem);
    transition-property: opacity, transform
}

.twlo-video-title {
    transition-duration: 300ms
}

.twlo-video-duration-container {
    transition-duration: 400ms
}

.twlo-video-overlay.is-visible {
    opacity: 1;
    visibility: visible
}

.twlo-video-overlay.is-visible .twlo-video-title, .twlo-video-overlay.is-visible .twlo-video-duration-container {
    opacity: 1;
    transform: translateY(0)
}

html, body {
    scroll-behavior: smooth
}

@media screen and (min-width: 900px) {
    html, body {
        /*overflow: hidden*/
    }
}

.docs-banner {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 20;
    background-color: white
}

.docs-layout-nav {
    min-height: 3rem
}

.docs-layout {
    overflow-x: hidden;
    margin-top: 3rem;
    scroll-behavior: smooth
}

@media screen and (min-width: 900px) {
    .docs-layout {
        overflow-y: auto;
        overflow-x: hidden;
        height: calc(100vh - 3rem)
    }
}

.docs-layout-sidebar {
    min-width: 270px;
    overflow-x: hidden
}

@media screen and (min-width: 900px) {
    .docs-layout-sidebar {
        height: 100%;
        position: fixed;
        overflow-y: auto;
        max-width: 270px
    }
}

.docs-layout-sidebar + .docs-layout-main {
    max-width: 100%
}

@media screen and (min-width: 900px) {
    .docs-layout-sidebar + .docs-layout-main {
        position: relative;
        overflow: hidden;
        margin-left: 270px
    }
}

@media screen and (min-width: 900px) {
    .docs-layout-multi-columns {
        height: calc(100vh - 3rem);
        display: flex
    }
}

.docs-layout-column {
    flex: 1;
    overflow-x: hidden;
    scroll-behavior: smooth
}

@media screen and (min-width: 900px) {
    .docs-layout-column {
        height: calc(100vh - 3rem);
        overflow-y: auto
    }
}

.docs-layout-column::-webkit-scrollbar, .docs-layout-column::-webkit-scrollbar-thumb {
    width: 4px;
    height: 4px
}

.docs-layout-column::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2)
}

.docs-layout-page-padding {
    padding: 4rem 5%
}

@media screen and (min-width: 1200px) {
    .docs-layout-page-padding {
        padding: 5%
    }
}

@media screen and (min-width: 900px) {
    .docs-layout-main.docs-layout-page-padding + .twlo-footer {
        margin-left: 270px
    }
}

.grecaptcha-badge {
    width: 0 !important
}

.docs-landing {
    background: linear-gradient(to bottom, rgba(0, 140, 255, 0.05), white)
}

.docs-landing-header {
    margin-bottom: 3rem;
    display: flex;
    align-items: center;
    justify-content: center
}

@media screen and (max-width: 899px) {
    .docs-landing-header {
        height: 300px;
        margin-bottom: 3rem;
        flex-direction: column;
        align-items: flex-start
    }
}

.docs-landing-chat {
    position: relative;
    min-height: 5.125rem;
    width: 12.1875rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10rem;
    margin-right: 2rem
}

@media screen and (max-width: 899px) {
    .docs-landing-chat {
        margin-bottom: 1rem
    }
}

.docs-landing-chat__bubble {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: -1
}

@media screen and (max-width: 899px) {
    .docs-landing-chat__bubble {
        transform: scaleX(-1)
    }
}

.docs-landing-chat__text {
    font-size: 1.4rem;
    line-height: 1.45;
    font-weight: 500;
    color: white;
    transform: translateY(-0.25rem);
    -webkit-font-smoothing: antialiased
}

.docs-landing-title {
    font-size: 3rem;
    line-height: 1.1;
    margin-bottom: 1rem
}

.docs-landing-subtitle {
    font-size: 1.2rem;
    line-height: 1.65;
    color: #565B73;
    max-width: 45ch;
    -webkit-font-smoothing: antialiased
}

.docs-landing-cards {
    display: grid;
    /*grid-template-columns: repeat(3, [col-start] 1fr);*/
    grid-gap: 4rem 3rem;
    max-width: 1200px;
    margin: 0 auto
}

.docs-landing__card-container {
    grid-column: span 1;
    animation: fadeInUp 400ms backwards
}

.docs-landing__card-container:nth-of-type(1) {
    animation-delay: 100ms
}

.docs-landing__card-container:nth-of-type(2) {
    animation-delay: 150ms
}

.docs-landing__card-container:nth-of-type(3) {
    animation-delay: 200ms
}

.docs-landing__card-container:nth-of-type(4) {
    animation-delay: 250ms
}

.docs-landing__card-container:nth-of-type(5) {
    animation-delay: 300ms
}

.docs-landing__card-container:nth-of-type(6) {
    animation-delay: 350ms
}

.docs-landing__card-container:nth-child(1) .docs-landing__card-header {
    background: linear-gradient(160deg, #C73F67, #A84B7E)
}

.docs-landing__card-container:nth-child(2) .docs-landing__card-header {
    background: linear-gradient(160deg, #A74C80, #885797)
}

.docs-landing__card-container:nth-child(3) .docs-landing__card-header {
    background: linear-gradient(160deg, #875898, #6864AF)
}

.docs-landing__card-container:nth-child(4) .docs-landing__card-header {
    background: linear-gradient(160deg, #BD436E, #9F4E85)
}

.docs-landing__card-container:nth-child(5) .docs-landing__card-header {
    background: linear-gradient(160deg, #9D4F87, #7F5B9E)
}

.docs-landing__card-container:nth-child(6) .docs-landing__card-header {
    background: linear-gradient(160deg, #7D5CA0, #5F68B7)
}

@media screen and (max-width: 899px) {
    .docs-landing__card-container {
        grid-column: span 3
    }
}

.docs-landing__card {
    background: white;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0 8px 48px 4px rgba(86, 91, 115, 0.15);
    transition-property: all;
    transition-duration: 200ms
}

@media screen and (min-width: 900px) {
    .docs-landing__card {
        align-self: stretch
    }
}

.docs-landing__card:hover {
    transform: translateY(-2px);
    box-shadow: 0 16px 60px 0 rgba(86, 91, 115, 0.2)
}

.docs-landing__card:active {
    transition: none;
    transform: translateY(-1px)
}

.docs-landing__card-title {
    font-size: 1.8rem;
    line-height: 1.35
}

.docs-landing__card-content {
    padding: 2rem;
    font-size: 1rem;
    line-height: 1.65;
    color: #565B73
}

.docs-landing__card-header {
    background-color: rgba(0, 0, 0, 0);
    padding: 1.25rem 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.4rem;
    line-height: 1.45;
    font-weight: 500;
    color: white;
    -webkit-font-smoothing: antialiased
}

.docs-landing__card-arrow::before {
    content: '';
    border: solid white;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 4px;
    transform: rotate(-45deg) translateY(-1px);
    transition: transform 200ms cubic-bezier(0, 0, 0.2, 1)
}

.docs-landing__card-arrow::after {
    content: '';
    display: inline-block;
    background-color: white;
    height: 2px;
    width: 10px;
    margin-left: 0.25em;
    animation: none 1.1s infinite;
    transition: transform 200ms cubic-bezier(0, 0, 0.2, 1)
}

.cursor-hover:hover .docs-landing__card-arrow::before {
    transform: rotate(-45deg) translateY(8px) translateX(8px)
}

.cursor-hover:hover .docs-landing__card-arrow::after {
    transform: translateX(-6px) translateY(-4px) scaleX(1.5);
    animation: none
}

@media screen and (max-width: 899px) {
    .docs-landing__card-arrow::before {
        transform: rotate(-45deg) translateY(8px) translateX(8px)
    }

    .docs-landing__card-arrow::after {
        transform: translateX(-6px) translateY(-4px) scaleX(1.5);
        animation: none
    }
}

.docs-landing-quest {
    font-size: 0.9rem;
    line-height: 1.6;
    color: #008CFF;
    opacity: 0.999;
    text-decoration: underline;
    display: block;
    margin-top: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center
}

.docs-landing-quest:hover, .docs-landing-quest:visited {
    color: #0062b3
}

.docs-landing-quest > svg {
    fill: currentColor
}

.docs-landing-quest__img {
    height: 32px;
    margin-right: 1rem
}

.docs-landing-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 400px
}

@media screen and (max-width: 899px) {
    .docs-landing-footer {
        height: 200px
    }
}

.docs-all-header {
    text-align: center;
    margin-bottom: 8%
}

@media screen and (max-width: 479px) {
    .docs-all-header {
        text-align: left;
        margin-bottom: 4rem
    }
}

.docs-all-title {
    font-size: 2.6rem;
    line-height: 1.15
}

.docs-all-products {
    max-width: 1800px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    list-style: none
}

.docs-product-grid {
    display: grid;
    /*grid-template-columns: repeat(6, [ col-start ] 1fr);*/
    grid-gap: 3rem
}

@media screen and (max-width: 899px) {
    .docs-product-grid {
        grid-gap: 0
    }
}

.docs-product-grid__item {
    grid-column: span 6
}

@media screen and (max-width: 899px) {
    .docs-product-grid__item > .twlo-code {
        padding: 0
    }
}

.docs-product-subgrid {
    display: grid;
    grid-gap: 3rem;
    grid-column: span 6
}

.docs-product-subgrid--vertical {
    grid-template-columns: 1fr
}

.docs-product-subgrid--horizontal {
    /*grid-template-columns: repeat(6, [ col-start ] 1fr)*/
}

.docs-product-subgrid__item {
    grid-column: span 6
}

@media screen and (min-width: 900px) {
    .docs-product-grid__item {
        grid-column: span 2
    }

    .docs-product-grid__item--left {
        grid-column: col-start / span 3
    }

    .docs-product-grid__item--right {
        grid-column: col-start 4 / span 3;
        grid-row: 1 / 3
    }

    .docs-product-subgrid--vertical {
        grid-column: span 2;
        grid-row: 2
    }

    .docs-product-subgrid--horizontal {
        grid-row: 3
    }

    .docs-product-subgrid__item {
        grid-column: span 2
    }
}

.docs-product-title {
    font-size: 3rem;
    line-height: 1.1;
    margin-bottom: 0.5em
}

@media screen and (max-width: 479px) {
    .docs-product-title {
        font-size: 2.5rem
    }
}

.docs-product-subtitle {
    font-size: 1.2rem;
    line-height: 1.65;
    color: #565B73;
    max-width: 60ch;
    margin-bottom: 3rem
}

.docs-product-subtitle > p {
    margin-bottom: 1.5em
}

@media screen and (max-width: 899px) {
    .docs-product-subtitle > p {
        margin-bottom: 1rem
    }
}

.docs-product-subtitle a {
    color: #008CFF;
    opacity: 0.999;
    text-decoration: underline
}

.docs-product-subtitle a:hover, .docs-product-subtitle a:visited {
    color: #0062b3
}

.docs-product-subtitle a > svg {
    fill: currentColor
}

@media screen and (max-width: 899px) {
    .docs-product-subtitle {
        margin-bottom: 1.5rem
    }
}

.docs-product-section {
    min-width: 100%;
    margin-top: 5rem;
    padding-top: 5rem;
    border-top: 1px solid rgba(148, 151, 155, 0.2)
}

@media screen and (max-width: 899px) {
    .docs-product-section {
        margin-top: 2rem;
        padding-top: 2rem
    }
}

.docs-product-section__title {
    font-size: 2.2rem;
    line-height: 1.25
}

.docs-product-section__title:after {
    content: '';
    display: block;
    margin-top: 0.75em;
    margin-bottom: 0.75em;
    width: 2.5em;
    height: 4px;
    border-top: 4px solid #008CFF
}

.docs-product-anchors__title {
    font-size: 0.75rem;
    line-height: 1.7;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    color: #94979B;
    margin-bottom: 4rem
}

@media screen and (max-width: 899px) {
    .docs-product-anchors__title {
        margin-bottom: 2rem
    }
}

.docs-product-anchors__list {
    display: flex;
    align-items: center;
    align-items: flex-start;
    list-style: none
}

@media screen and (max-width: 899px) {
    .docs-product-anchors__list {
        flex-direction: column
    }
}

.docs-product-anchors__list-item:not(:last-child) {
    margin-right: 3.5rem
}

@media screen and (max-width: 899px) {
    .docs-product-anchors__list-item:not(:last-child) {
        margin-bottom: 1.5rem
    }
}

.docs-product-anchors__link {
    font-size: 0.9rem;
    font-weight: 500;
    line-height: 1.6;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    white-space: nowrap;
    color: #565B73
}

.docs-product-anchors__link svg {
    vertical-align: middle;
    transform: translateY(-2px);
    margin-right: 1rem
}

.docs-product-anchors__link:hover {
    color: #008CFF;
    opacity: 0.999
}

.docs-product-footer__header {
    margin-bottom: 4rem
}

@media screen and (max-width: 899px) {
    .docs-product-footer__header {
        margin-bottom: 2rem
    }
}

.docs-product-footer__title {
    font-size: 1.1rem;
    font-weight: 500;
    line-height: 1.6;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    margin-bottom: 2rem
}

.docs-product-footer__subtitle {
    font-size: 1.2rem;
    line-height: 1.65;
    color: #565B73;
    max-width: 85ch
}

.docs-article {
    --article-padding: 1.5rem;
    max-width: 800px;
    margin: 0 auto
}

@media screen and (min-width: 480px) {
    .docs-article {
        --article-padding: 3rem;
        margin-bottom: 10rem
    }
}

.docs-article__title {
    font-size: 2.6rem;
    line-height: 1.15;
    margin-bottom: 2rem;
    border-bottom: 1px solid rgba(148, 151, 155, 0.2);
    padding-bottom: 1rem
}

.docs-article__subtitle {
    font-size: 1.2rem;
    line-height: 1.65;
    color: #565B73
}

.docs-article__section-title {
    margin-bottom: 2rem;
    line-height: 1.4
}

h2.docs-article__section-title {
    font-size: 1.8rem;
    border-top: 1px solid rgba(148, 151, 155, 0.2);
    padding-top: 2rem
}

h3.docs-article__section-title {
    font-size: 1.6rem
}

h4.docs-article__section-title, h5.docs-article__section-title, h6.docs-article__section-title {
    font-size: 1.4rem
}

header + section > div > h2.docs-article__section-title:first-child {
    border-top: none;
    padding-top: 0
}

.toclink {
    position: relative
}

.toclink:hover::before {
    content: '#';
    color: #008CFF;
    opacity: 0.999;
    position: absolute;
    left: -1.5rem
}

.docs-article__list {
    list-style-position: outside;
    padding: 2rem;
    padding-left: 3.5rem;
    background-color: #F9F9F9;
    border-radius: 4px;
    box-shadow: inset 0 0 0 1px rgba(148, 151, 155, 0.2)
}

.docs-article__list li:not(:last-child) {
    margin-bottom: 1rem
}

.docs-article__list.docs-article__list-nested {
    box-shadow: none;
    padding: 0 2rem
}

li.page-group {
    list-style: none
}

.docs-prose {
    line-height: 1.6
}

.docs-prose > header, .docs-prose > section, .docs-prose > footer {
    padding: 1rem var(--article-padding)
}

.docs-prose > header > *, .docs-prose > section > *, .docs-prose > footer > * {
    margin-bottom: 2rem
}

.docs-prose > header > *:last-child, .docs-prose > section > *:last-child, .docs-prose > footer > *:last-child {
    margin-bottom: 0
}

.docs-prose section > .rich-text > *, .docs-prose section > .markdown > * {
    margin-bottom: 2rem
}

.docs-prose section > .rich-text > *:last-child, .docs-prose section > .markdown > *:last-child {
    margin-bottom: 0
}

.docs-prose section.is-hidden {
    display: none
}

.docs-prose p a:not(.twlo-link-btn), .docs-prose ol a:not(.twlo-link-btn), .docs-prose ul a:not(.twlo-link-btn), .docs-prose table a:not(.twlo-link-btn) {
    color: #008CFF;
    opacity: 0.999;
    text-decoration: underline
}

.docs-prose p a:not(.twlo-link-btn):hover, .docs-prose p a:not(.twlo-link-btn):visited, .docs-prose ol a:not(.twlo-link-btn):hover, .docs-prose ol a:not(.twlo-link-btn):visited, .docs-prose ul a:not(.twlo-link-btn):hover, .docs-prose ul a:not(.twlo-link-btn):visited, .docs-prose table a:not(.twlo-link-btn):hover, .docs-prose table a:not(.twlo-link-btn):visited {
    color: #0062b3
}

.docs-prose p a:not(.twlo-link-btn) > svg, .docs-prose ol a:not(.twlo-link-btn) > svg, .docs-prose ul a:not(.twlo-link-btn) > svg, .docs-prose table a:not(.twlo-link-btn) > svg {
    fill: currentColor
}

.docs-prose hr {
    border: none;
    border-bottom: 1px solid rgba(148, 151, 155, 0.4)
}

@media screen and (max-width: 479px) {
    .docs-prose .twlo-code {
        width: 100vw;
        min-width: 100vw;
        border-radius: 0;
        margin-left: calc(var(--article-padding) * -1) !important;
        margin-right: calc(var(--article-padding) * -1) !important
    }
}

.docs-prose :not(pre) > code:not(.docs-api-param-name) {
    color: #F22F46;
    background-color: #F9F9F9;
    box-shadow: inset 0 0 0 1px #E8E8E8;
    font-size: inherit;
    line-height: 1.2;
    padding: 0.15em 0.4em;
    border-radius: 4px;
    display: inline-block
}

.docs-prose p img {
    max-width: 100%;
    display: block;
    margin: 4rem 0;
    filter: brightness(0.95)
}

@media screen and (max-width: 479px) and (max-width: 479px) {
    .docs-prose p img {
        width: 100vw;
        min-width: 100vw;
        border-radius: 0;
        margin-left: calc(var(--article-padding) * -1) !important;
        margin-right: calc(var(--article-padding) * -1) !important
    }
}

.docs-prose video {
    width: 100%;
    display: block;
    cursor: pointer
}

@media screen and (min-width: 480px) {
    .docs-prose img, .docs-prose video {
        border-radius: 4px;
        overflow: hidden
    }
}

.docs-prose figure {
    margin: 4rem 0
}

@media screen and (max-width: 479px) and (max-width: 479px) {
    .docs-prose figure {
        width: 100vw;
        min-width: 100vw;
        border-radius: 0;
        margin-left: calc(var(--article-padding) * -1) !important;
        margin-right: calc(var(--article-padding) * -1) !important
    }
}

@media screen and (max-width: 479px) {
    .docs-prose figure figcaption {
        padding-left: 1rem;
        padding-right: 1rem
    }
}

.docs-prose figure .docs-modal {
    width: auto
}

@media screen and (min-width: 900px) {
    .docs-prose figure .docs-modal {
        max-width: 1200px
    }
}

@media screen and (min-width: 1200px) {
    .docs-prose figure .docs-modal {
        max-width: 1400px
    }
}

.docs-prose figure .docs-modal-overlay {
    align-items: flex-start;
    overflow: auto
}

.docs-prose td > figure {
    margin: auto
}

.docs-prose figure img {
    display: block;
    height: auto
}

.docs-prose figcaption {
    font-size: 0.75rem;
    line-height: 1.5;
    color: #94979B;
    margin-top: 1rem;
    color: #94979B
}

.docs-prose blockquote {
    font-size: 1.4rem;
    line-height: 1.45;
    font-weight: 500;
    margin: 4rem 0;
    border-left: 5px solid #f5f5f5;
    padding-left: 5%
}

.docs-prose cite {
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.55;
    color: #94979B;
    display: block;
    margin-top: 1.5rem
}

.docs-prose table {
    min-width: 100%;
    border-collapse: collapse;
    border: 1px solid rgba(148, 151, 155, 0.2);
    font-size: 0.9rem;
    line-height: 1.6;
    table-layout: fixed;
    width: 100%
}

@media screen and (max-width: 479px) {
    .docs-prose table {
        width: 100vw;
        min-width: 100vw;
        border-radius: 0;
        margin-left: calc(var(--article-padding) * -1) !important;
        margin-right: calc(var(--article-padding) * -1) !important
    }
}

@media screen and (max-width: 479px) {
    .docs-prose table {
        border-left: 0;
        border-right: 0
    }
}

.docs-prose thead {
    white-space: normal;
    background-color: #f5f5f5;
    color: #565B73;
    text-align: left
}

.docs-prose tbody {
    vertical-align: top
}

.docs-prose tr + tr {
    border-top: 1px solid rgba(148, 151, 155, 0.2)
}

@media screen and (max-width: 479px) {
    .docs-prose tr {
        display: flex;
        flex-direction: column;
        flex: 1
    }
}

.docs-prose th {
    padding: 0.75rem 2rem
}

.docs-prose td {
    padding: 2rem;
    overflow-x: auto;
    word-wrap: break-word
}

@media screen and (max-width: 479px) {
    .docs-prose td:not(:last-child) {
        padding-bottom: 0
    }
}

.docs-not-found-wrap {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center
}

.docs-not-found {
    text-align: center;
    margin: 10%
}

.docs-not-found__header {
    display: inline-block;
    color: #F22F46;
    border: 1px solid;
    border-radius: 4px;
    margin-bottom: 2rem;
    white-space: nowrap
}

.docs-not-found__code, .docs-not-found__title {
    font-family: "Fira Mono", monospace;
    font-size: 0.9rem;
    line-height: 1.6;
    font-size: 1rem;
    display: inline-block;
    padding: 1rem 1.5rem
}

.docs-not-found__code {
    border-right: 1px solid;
    background-color: rgba(242, 47, 70, 0.075)
}

.docs-not-found__body p {
    font-size: 1rem;
    line-height: 1.65
}

@media screen and (max-width: 479px) {
    .docs-not-found__body p {
        display: inline
    }
}

.docs-glossary nav {
    background: #152748;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 3rem;
    color: #fff
}

.docs-glossary nav > a.nav-link {
    color: #fff;
    text-decoration: none !important
}

.docs-glossary nav > a.nav-link:hover {
    color: #008CFF
}

.docs-glossary nav > a.nav-link:visited {
    color: #fff
}

.docs-glossary nav > a.nav-link.empty {
    color: #94979B !important;
    cursor: default
}

.docs-glossary a:not(.toclink):not(.twlo-btn):not(.twlo-btn-red) {
    color: #008CFF;
    opacity: 0.999;
    text-decoration: underline
}

.docs-glossary a:not(.toclink):not(.twlo-btn):not(.twlo-btn-red):hover, .docs-glossary a:not(.toclink):not(.twlo-btn):not(.twlo-btn-red):visited {
    color: #0062b3
}

.docs-glossary a:not(.toclink):not(.twlo-btn):not(.twlo-btn-red) > svg {
    fill: currentColor
}

.docs-glossary .docs-glossary__index {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100% - 150px);
    padding: 1rem;
    width: 80%;
    margin: auto
}

.docs-glossary .docs-glossary__index section {
    --article-padding: 3rem;
    border-bottom: 1px solid rgba(148, 151, 155, 0.2);
    padding: calc(var(--article-padding) + 1rem) var(--article-padding)
}

.docs-glossary .docs-glossary__index section .docs-glossary__section-title {
    font-size: 1.4rem;
    line-height: 1.45;
    font-weight: 500;
    margin-bottom: 2rem;
    text-align: center
}

.docs-glossary .docs-glossary__index section ul {
    list-style: none
}

.docs-glossary .docs-glossary__index section ul li {
    padding-bottom: 1.4rem
}

.docs-glossary .docs-glossary__index section ul li h4 {
    font-size: 1.1rem;
    font-weight: 500;
    padding-bottom: 0.8rem
}

.docs-glossary .docs-glossary__index section .top {
    text-align: center;
    display: block;
    color: #94979B;
    font-size: 0.8rem
}

.docs-glossary .docs-glossary__entry {
    padding: 1rem;
    width: 80%;
    margin: auto;
    line-height: 1.6
}

.docs-glossary .docs-glossary__entry > section, .docs-glossary .docs-glossary__entry > footer {
    --article-padding: 1rem;
    padding: calc(var(--article-padding) + 1rem) var(--article-padding)
}

.docs-glossary .docs-glossary__entry > section > *, .docs-glossary .docs-glossary__entry > footer > * {
    margin-bottom: 2rem
}

.docs-glossary .docs-glossary__entry > section > *:last-child, .docs-glossary .docs-glossary__entry > footer > *:last-child {
    margin-bottom: 0
}

.docs-glossary .docs-glossary__entry section > .rich-text > *, .docs-glossary .docs-glossary__entry section > .markdown > * {
    margin-bottom: 2rem
}

.docs-glossary .docs-glossary__entry section > .rich-text > *:last-child, .docs-glossary .docs-glossary__entry section > .markdown > *:last-child {
    margin-bottom: 0
}

.docs-glossary .docs-glossary__entry .docs-glossary__title {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid rgba(148, 151, 155, 0.2);
    justify-content: space-between;
    margin-bottom: 1.5rem
}

.docs-glossary .docs-glossary__entry .docs-glossary__title h1 {
    font-size: 2.6rem;
    line-height: 1.15;
    align-self: center
}

.docs-glossary__cta {
    text-align: center
}

.docs-glossary__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between
}

.docs-errorcode__pages {
    text-align: center
}

.docs-errorcode__links {
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-size: 0.625rem;
    font-weight: 500;
    line-height: 1;
    display: inline-block;
    padding: 0.75em 1em;
    margin-right: 0.75em;
    margin-bottom: 0.75em;
    box-shadow: inset 0 0 0 1px rgba(148, 151, 155, 0.2);
    border-radius: 2px;
    transition: background-color 100ms
}

.docs-errorcode__links:hover {
    background: white
}

.docs-errorcode__links.is-selected {
    color: white;
    background-color: #008CFF;
    box-shadow: none;
    animation: pulseBlue 500ms
}

.docs-errorcode-table .docs-api-tag--error {
    font-weight: 600;
    color: #F22F46
}

.docs-errorcode-table .docs-api-tag--warning {
    font-weight: 600;
    color: #EFDC2E
}

.docs-nav {
    display: flex;
    background-color: #233659
}

.docs-nav__primary, .docs-nav__secondary {
    list-style: none;
    display: none;
    margin-bottom: 0px;
}

@media screen and (min-width: 975px) {
    .docs-nav__primary, .docs-nav__secondary {
        display: flex
    }
}

.docs-nav__brand {
    display: flex;
    align-items: center;
    white-space: nowrap
}

.docs-nav__logo {
    margin-right: 1rem;
    height: 1.5rem;
    width: 1.5rem
}

.docs-nav__title {
    font-weight: 500;
    font-size: 1rem
}

.docs-nav__item {
    font-size: 0.9rem;
    user-select: none;
    white-space: nowrap
}

.docs-nav__link {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: white;
    padding-left: 1rem;
    padding-right: 1rem;
    text-decoration: none;
    cursor: pointer
}

.docs-nav__link:hover {
    background-color: rgba(255, 255, 255, 0.1)
}

.docs-nav__secondary {
    margin-left: auto
}

.docs-nav__login, .docs-nav__signup, .docs-nav__console {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    height: 100%;
    cursor: pointer;
    color: white
}

.docs-nav__login, .docs-nav__signup {
    width: 6rem
}

.docs-nav__login {
    background: rgba(255, 255, 255, 0.15);
    margin-left: 1rem
}

.docs-nav__signup {
    background: #F22F46
}

.docs-nav__console {
    background: rgba(255, 255, 255, 0.15);
    margin-left: 1rem
}

.docs-nav__console .twlo-icon-interface-user {
    margin-right: 0.5rem;
    transform: translateY(-1px)
}

.docs-nav-mobile {
    margin-left: auto;
    z-index: 1
}

@media screen and (min-width: 975px) {
    .docs-nav-mobile {
        display: none
    }
}

.docs-navicon {
    height: 100%;
    z-index: 1
}

.docs-navicon__trigger {
    display: flex;
    align-items: center;
    padding: 0 1.5rem;
    height: 100%;
    cursor: pointer;
    transition: transform 250ms cubic-bezier(0.5, -0.5, 0.5, 1.5)
}

.docs-navicon__lines {
    height: 11px;
    width: 12px;
    position: relative
}

.docs-navicon__lines--center, .docs-navicon__lines:before, .docs-navicon__lines:after {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    content: '';
    display: block;
    height: 1px;
    width: 100%;
    background-color: white;
    transform-origin: left;
    transition-property: transform, opacity;
    transition-duration: 250ms
}

.docs-navicon__lines--center {
    top: 0;
    bottom: 0
}

.docs-navicon__lines:before {
    top: 0
}

.docs-navicon__lines:after {
    bottom: 0
}

.docs-navicon.is-open .docs-navicon__trigger {
    transform: rotate(-90deg)
}

.docs-navicon.is-open .docs-navicon__lines--center {
    opacity: 0
}

.docs-navicon.is-open .docs-navicon__lines:before {
    width: 14px;
    transform: rotate(45deg)
}

.docs-navicon.is-open .docs-navicon__lines:after {
    width: 14px;
    transform: rotate(-45deg)
}

.docs-nav-mobile__menu {
    position: fixed;
    min-height: 100vh;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0;
    padding: 3rem 8%;
    padding-bottom: 10rem;
    z-index: -1;
    overflow-y: auto;
    /*columns: two;*/
    color: white;
    background: #152748;
    font-weight: 100;
    list-style: none;
    text-align: left;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition-property: opacity, visibility;
    transition-duration: 200ms
}

.docs-nav-mobile__menu.is-open {
    opacity: 1;
    visibility: visible;
    pointer-events: inherit
}

.docs-nav-mobile__menu li {
    font-size: 1.8rem;
    line-height: 1.35;
    margin-bottom: 1.5rem
}

.docs-nav-mobile__menu.is-open li {
    animation: fadeInUp 400ms backwards
}

.docs-nav-mobile__menu.is-open li:nth-of-type(1) {
    animation-delay: 100ms
}

.docs-nav-mobile__menu.is-open li:nth-of-type(2) {
    animation-delay: 150ms
}

.docs-nav-mobile__menu.is-open li:nth-of-type(3) {
    animation-delay: 200ms
}

.docs-nav-mobile__menu.is-open li:nth-of-type(4) {
    animation-delay: 250ms
}

.docs-nav-mobile__menu.is-open li:nth-of-type(5) {
    animation-delay: 300ms
}

.docs-nav-mobile__menu.is-open li:nth-of-type(6) {
    animation-delay: 350ms
}

.docs-nav-mobile__menu.is-open li:nth-of-type(7) {
    animation-delay: 400ms
}

.docs-nav-mobile__menu.is-open li:nth-of-type(8) {
    animation-delay: 450ms
}

.docs-nav-mobile__menu.is-open li:nth-of-type(9) {
    animation-delay: 500ms
}

.docs-nav-mobile__menu.is-open li:nth-of-type(10) {
    animation-delay: 550ms
}

.docs-nav-mobile__menu.is-open li:nth-of-type(11) {
    animation-delay: 600ms
}

.docs-nav-mobile__search {
    border: none;
    background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%3E%3Cpath%20fill%3D%22%23fff%22%20d%3D%22M6%2011l1%201-3%203H3v-1l3-3zm9-3.5c0%202.5-2%204.5-4.5%204.5S6%2010%206%207.5%208%203%2010.5%203%2015%205%2015%207.5zm-1%200C14%205.6%2012.4%204%2010.5%204S7%205.6%207%207.5%208.6%2011%2010.5%2011%2014%209.4%2014%207.5z%22%2F%3E%3C%2Fsvg%3E");
    background-color: rgba(255, 255, 255, 0.075);
    background-size: 2rem;
    background-repeat: no-repeat;
    background-position: left 1rem center;
    width: 100%;
    padding: 0.5rem;
    padding-left: 4rem;
    border-radius: 4px;
    transition-property: width, background;
    transition-duration: 200ms;
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1)
}

.docs-nav-mobile__search:focus {
    outline: none;
    background-color: rgba(255, 255, 255, 0.15)
}

.docs-nav-mobile__link {
    transition: color 100ms
}

.docs-nav-mobile__link:hover, .docs-nav-mobile__link:focus {
    color: #F22F46
}

.docs-nav-mobile__login, .docs-nav-mobile__signup {
    font-size: 1.8rem;
    line-height: 1.35;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.15);
    display: block;
    padding: 0.5rem 1rem;
    border-radius: 4px
}

.docs-nav-mobile__signup {
    background-color: #F22F46
}

.docs-nav-mega-menu {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    height: 100%;
    z-index: 1
}

@media screen and (max-width: 899px) {
    .docs-nav-mega-menu {
        flex-direction: column
    }
}

.docs-nav-mega-menu__left-column, .docs-nav-mega-menu__right-column {
    flex: 1;
    padding: 8%;
    background-color: white
}

.docs-nav-mega-menu__left-column {
    color: white;
    background: linear-gradient(160deg, #565B73, #233659)
}

@media screen and (max-width: 899px) {
    .docs-nav-mega-menu__left-column {
        flex: 0
    }
}

.docs-nav-mega-menu__title {
    font-size: 2.6rem;
    line-height: 1.15
}

.docs-nav-mega-menu__title:after {
    content: '';
    display: block;
    margin-top: 0.75em;
    margin-bottom: 0.75em;
    width: 2.5em;
    height: 4px;
    border-top: 4px solid #F22F46
}

.docs-nav-mega-menu__subtitle {
    font-size: 1.2rem;
    line-height: 1.65;
    margin-bottom: 4rem
}

.docs-nav-mega-menu__cta {
    font-family: inherit;
    text-decoration: none;
    text-shadow: none;
    appearance: none;
    border: none;
    outline: none;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 64px;
    max-width: 100%;
    padding-left: 2em;
    padding-right: 2em;
    border-radius: 4px;
    flex-shrink: 0;
    min-width: min-content;
    backface-visibility: hidden;
    font-size: 1rem;
    line-height: 1.65;
    color: #565B73;
    background-color: #fff;
    box-shadow: 0 8px 48px 4px rgba(86, 91, 115, 0.15);
    will-change: background-color, transform, box-shadow;
    transition-property: background-color, transform, box-shadow;
    transition-duration: 200ms;
    transition-property: all;
    transition-duration: 200ms
}

.docs-nav-mega-menu__cta:hover {
    transform: translateY(-2px);
    box-shadow: 0 16px 60px 0 rgba(86, 91, 115, 0.2)
}

.docs-nav-mega-menu__cta:active {
    transition: none;
    transform: translateY(-1px)
}

.docs-nav-mega-menu__cta:hover {
    color: #565B73;
    background-color: #fff
}

.docs-nav-mega-menu__cta:disabled, .docs-nav-mega-menu__cta.disabled {
    opacity: 0.5;
    cursor: not-allowed
}

@media screen and (min-width: 480px) {
    .docs-nav-mega-menu__cta {
        display: inline-flex
    }
}

.docs-nav-mega-menu__close-button {
    content: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%3E%3Cpath%20fill%3D%22%23565B73%22%20d%3D%22M15%204l-5%205%205%205v1h-1l-5-5-5%205H3v-1l5-5-5-5V3h1l5%205%205-5h1v1z%22%2F%3E%3C%2Fsvg%3E");
    position: fixed;
    top: 1rem;
    right: 1rem;
    background: rgba(86, 91, 115, 0.075);
    padding: 1rem;
    border-radius: 100%;
    cursor: pointer;
    transition: background 100ms;
    cursor: pointer;
    user-select: none
}

.docs-nav-mega-menu__close-button:hover {
    background: rgba(86, 91, 115, 0.125)
}

.docs-nav-mega-menu__section:not(:last-child) {
    margin-bottom: 3rem;
    padding-bottom: 3rem;
    border-bottom: 1px solid rgba(148, 151, 155, 0.2)
}

.docs-nav-mega-menu__section-title {
    font-size: 0.75rem;
    line-height: 1.7;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    color: #94979B;
    margin-bottom: 2rem
}

.docs-nav-mega-menu__list {
    list-style: none
}

.docs-nav-mega-menu__list li {
    margin-bottom: 1rem
}

.docs-sidebar {
    background-color: #F9F9F9
}

@media screen and (max-width: 899px) {
    .docs-sidebar {
        border-bottom: 1px solid rgba(148, 151, 155, 0.2)
    }
}

@media screen and (min-width: 900px) {
    .docs-sidebar {
        padding-bottom: 10rem;
        border-right: 1px solid rgba(148, 151, 155, 0.2)
    }
}

.docs-sidebar__header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 5rem;
    padding: 1.5rem;
    padding-right: 0;
    border-bottom: 1px solid rgba(148, 151, 155, 0.2);
    z-index: 0;
    min-width: 270px
}

@media screen and (min-width: 900px) {
    .docs-sidebar__header {
        position: fixed;
        border-right: 1px solid rgba(148, 151, 155, 0.2);
        z-index: 1;
        max-width: 270px;
        background-color: #F9F9F9
    }
}

.docs-sidebar__title, .docs-sidebar__status-title {
    font-weight: 500
}

.docs-sidebar-title:hover {
    color: #008CFF;
    opacity: 0.999;
    text-decoration: underline
}

.docs-sidebar-title:hover:hover, .docs-sidebar-title:hover:visited {
    color: #0062b3
}

.docs-sidebar-title:hover > svg {
    fill: currentColor
}

.docs-sidebar__status {
    padding: 1.5rem;
    padding-left: 1rem
}

.docs-sidebar__status:hover .docs-sidebar__status-indicator {
    background: white
}

.docs-sidebar__status:hover .docs-sidebar__status-overlay {
    opacity: 1;
    visibility: visible
}

.docs-sidebar__status:hover .docs-sidebar__status-title, .docs-sidebar__status:hover .docs-sidebar__status-subtitle {
    animation: fadeInUp 200ms
}

.docs-sidebar__status:hover .docs-sidebar__status-subtitle {
    animation-delay: 50ms
}

@media screen and (max-width: 479px) {
    .docs-sidebar__status {
        pointer-events: none
    }
}

.docs-sidebar__status-indicator {
    position: relative;
    height: 0.5rem;
    width: 0.5rem;
    border-radius: 100%;
    background: #36D576;
    cursor: pointer;
    z-index: 2
}

.docs-sidebar__status-indicator.warning {
    background: #EFDC2E
}

.docs-sidebar__status-indicator.error {
    background: #F22F46
}

.docs-sidebar__status-overlay {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: opacity 100ms, visibility 100ms;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-left: 1.5rem;
    font-size: 1rem;
    line-height: 1.65;
    color: white;
    background: #36D576;
    border-bottom: 1px solid rgba(148, 151, 155, 0.2)
}

.docs-sidebar__status-overlay.warning {
    background: #EFDC2E
}

.docs-sidebar__status-overlay.error {
    background: #F22F46
}

.docs-sidebar__status-subtitle {
    font-size: 0.9rem;
    line-height: 1.6;
    opacity: 0.8
}

.docs-sidebar__mobile-menu {
    display: flex;
    justify-content: space-between;
    padding: 1rem 1.5rem;
    color: #008CFF;
    opacity: 0.999;
    font-weight: 500;
    cursor: pointer;
    user-select: none
}

@media screen and (min-width: 900px) {
    .docs-sidebar__mobile-menu {
        display: none
    }
}

.docs-sidebar__mobile-menu .twlo-icon-interface-dropdown {
    transform: translateX(4px);
    transition: transform 100ms cubic-bezier(0, 0, 0.2, 1)
}

.docs-sidebar__mobile-menu.is-open .twlo-icon-interface-dropdown {
    transform: rotate(180deg) translateX(-4px)
}

.docs-sidebar__mobile-menu.is-open + .docs-sidebar__content {
    display: block;
    background: #f5f5f5;
    border-top: 1px solid rgba(148, 151, 155, 0.2)
}

.docs-sidebar__content {
    margin-top: 5rem
}

@media screen and (max-width: 899px) {
    .docs-sidebar__content {
        margin-top: 0;
        display: none;
        animation: fadeIn 250ms cubic-bezier(0, 0, 0.2, 1)
    }
}

.docs-sidebar__file-tree {
    list-style: none;
    padding-top: 1rem;
    padding-bottom: 1rem
}

.docs-sidebar__file-tree input[type=checkbox] {
    position: absolute;
    opacity: 0
}

.docs-sidebar__file-tree label, .docs-sidebar__file-tree a {
    position: relative;
    display: block;
    font-family: system, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-size: 12px;
    font-weight: 500;
    color: inherit;
    text-decoration: none;
    user-select: none;
    padding: 8px;
    padding-left: 1.5rem;
    margin-left: 24px;
    text-indent: -24px
}

.docs-sidebar__file-tree label:hover, .docs-sidebar__file-tree a:hover {
    color: #008CFF;
    opacity: 0.999
}

.docs-sidebar__file-tree label::before, .docs-sidebar__file-tree a::before {
    content: '';
    display: inline-block;
    width: 16px;
    height: 16px;
    background-position: 0 0;
    background-repeat: no-repeat;
    margin-right: 4px;
    vertical-align: bottom;
    transition: opacity 100ms
}

.docs-sidebar__file-tree .child-subsection a, .docs-sidebar__file-tree .child-subsection label {
    font-size: 11px
}

.docs-sidebar__file-tree ul:not(.child-subsection) {
    list-style: none;
    display: none
}

.docs-sidebar__file-tree input[type=checkbox]:checked + label + ul, .docs-sidebar__file-tree input[type=checkbox]:checked + label:nth-of-type(n) + ul {
    display: block;
    animation: fadeIn 250ms cubic-bezier(0, 0, 0.2, 1)
}

.docs-sidebar__file-tree ul a, .docs-sidebar__file-tree ul label {
    margin-left: 32px
}

.docs-sidebar__file-tree ul ul a, .docs-sidebar__file-tree ul ul label {
    margin-left: 40px
}

.docs-sidebar__file-tree ul ul ul a, .docs-sidebar__file-tree ul ul ul label {
    margin-left: 48px
}

.docs-sidebar__file-tree .folder label::before {
    background: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%3E%3Cpath%20fill%3D%22%23008CFF%22%20d%3D%22M7%204h7v10H0V2h7v2zM1%203v1h5V3H1z%22%2F%3E%3C%2Fsvg%3E");
    opacity: 0.3
}

.docs-sidebar__file-tree .folder label {
    cursor: pointer
}

.docs-sidebar__file-tree .folder input:checked + label::before {
    background: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%3E%3Cpath%20fill%3D%22%23008CFF%22%20opacity%3D%22.3%22%20d%3D%22M7%204h7v10H0V2h7v2zM1%203v1h5V3H1z%22%2F%3E%3Cpath%20fill%3D%22%23008CFF%22%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M14%2014H0l2-7h14l-2%207z%22%2F%3E%3C%2Fsvg%3E");
    opacity: 1
}

.docs-sidebar__file-tree .folder:hover > label::before {
    opacity: 0.5
}

.docs-sidebar__file-tree .page label::before {
    background: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%3E%3Cpath%20fill%3D%22%23565B73%22%20d%3D%22M8.3%201H1v14h12V5.6L8.3%201zM4%2010h6v1H4v-1zm0-2h6v1H4V8zm0-2h4v1H4V6z%22%2F%3E%3C%2Fsvg%3E");
    opacity: 0.3
}

.docs-sidebar__file-tree .page input:checked + label::before, .docs-sidebar__file-tree .page a::before {
    background: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%3E%3Cpath%20fill%3D%22%23008CFF%22%20d%3D%22M8.3%201H1v14h12V5.6L8.3%201zM4%2010h6v1H4v-1zm0-2h6v1H4V8zm0-2h4v1H4V6z%22%2F%3E%3C%2Fsvg%3E");
    opacity: 1
}

.docs-sidebar__file-tree .page:hover > label::before, .docs-sidebar__file-tree .page:hover a::before {
    opacity: 1
}

.docs-sidebar__file-tree .page:not(.is-selected) a::before {
    background: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%3E%3Cpath%20fill%3D%22%23565B73%22%20d%3D%22M8.3%201H1v14h12V5.6L8.3%201zM4%2010h6v1H4v-1zm0-2h6v1H4V8zm0-2h4v1H4V6z%22%2F%3E%3C%2Fsvg%3E");
    opacity: 0.3
}

.docs-sidebar__file-tree .hash a::before {
    background: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%3E%3Cpath%20fill%3D%22%23565B73%22%20d%3D%22M4.4%209l.2-2H2V6h2.7L5%203h1l-.3%203h2L8%203h1l-.3%203H11v1H8.6l-.2%202H11v1H8.3L8%2013H7l.3-3h-2L5%2013H4l.3-3H2V9h2.4zm1%200h2l.2-2h-2l-.2%202z%22%2F%3E%3C%2Fsvg%3E");
    opacity: 0.4
}

.docs-sidebar__file-tree .hash:hover a::before {
    opacity: 1
}

.docs-sidebar__file-tree .code a::before {
    background: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%3E%3Cpath%20fill%3D%22%23565B73%22%20d%3D%22M2%2012v-.8L5.5%208%202%204.8V4h.8L7%208l-4.2%204H2zm5-1h6v1H7v-1z%22%2F%3E%3C%2Fsvg%3E");
    opacity: 0.4
}

.docs-sidebar__file-tree .code:hover a::before {
    opacity: 1
}

.docs-sidebar__file-tree .linkout a::before {
    background: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%208%208%22%3E%3Cpath%20fill%3D%22%23565B73%22%20d%3D%22M8%208H7V1.7L.9%207.9l-.8-.8L6.3%201H0V0h8v8z%22%2F%3E%3C%2Fsvg%3E");
    opacity: 0.4;
    background-repeat: no-repeat;
    background-position: center
}

.docs-sidebar__file-tree .linkout:hover a::before {
    opacity: 1
}

.docs-sidebar__file-tree .folder.is-selected, .docs-sidebar__file-tree .page.is-selected, .docs-sidebar__file-tree .hash.is-selected, .docs-sidebar__file-tree .linkout.is-selected, .docs-sidebar__file-tree .code.is-selected {
    color: #008CFF;
    opacity: 0.999;
    background: rgba(0, 140, 255, 0.07)
}

.docs-sidebar__file-tree .folder.is-selected a::before, .docs-sidebar__file-tree .page.is-selected a::before, .docs-sidebar__file-tree .hash.is-selected a::before, .docs-sidebar__file-tree .linkout.is-selected a::before, .docs-sidebar__file-tree .code.is-selected a::before {
    opacity: 1
}

.docs-sidebar__filters {
    padding: 2rem 1.5rem
}

.docs-sidebar__filter-group:not(:last-child) {
    margin-bottom: 3rem
}

.docs-sidebar__filter-group-title {
    font-size: 0.75rem;
    line-height: 1.7;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    color: #94979B;
    margin-bottom: 1rem;
    display: block
}

.docs-sidebar-toggle {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f5f5f5;
    height: 2rem;
    width: 1rem;
    margin: 1rem 0;
    border-radius: 0 4px 4px 0
}

.docs-sidebar-toggle::after {
    content: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%3E%3Cpath%20fill%3D%22%23008CFF%22%20d%3D%22M15%207l-6%206-6-6V6h1l5%205%205-5h1v1z%22%2F%3E%3C%2Fsvg%3E");
    transform: rotate(-90deg) translateY(2px)
}

.docs-sidebar-toggle.is-open::after {
    transform: rotate(90deg) translateY(2px)
}

@media screen and (max-width: 899px) {
    .docs-sidebar-toggle {
        display: none
    }
}

.docs-note {
    position: relative;
    font-size: 0.9rem;
    line-height: 1.6;
    max-width: none;
    background: #F9F9F9;
    border-left-width: 4px;
    border-left-style: solid;
    padding: 1rem 1.5rem
}

.docs-note::before {
    content: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%3E%3Cpath%20fill%3D%22%23fff%22%20d%3D%22M7%207h2v6H7V7zm0-4v2h2V3H7z%22%2F%3E%3C%2Fsvg%3E");
    position: absolute;
    top: 1rem;
    left: -12px;
    font-size: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 20px;
    border-radius: 100%
}

.docs-note--info {
    border-left-color: #36D576
}

.docs-note--info::before {
    background: #36D576
}

.docs-note--warning {
    border-left-color: #EFDC2E
}

.docs-note--warning::before {
    background: #EFDC2E
}

.docs-note--error {
    border-left-color: #F22F46
}

.docs-note--error::before {
    transform: rotate(180deg);
    background: #F22F46
}

.docs-article__code-cta {
    height: 100px;
    display: flex;
    align-items: center;
    position: relative;
    color: white;
    background-color: #008CFF;
    cursor: pointer;
    margin-left: calc(var(--article-padding) * -1) !important;
    margin-right: calc(var(--article-padding) * -1) !important
}

.docs-article__code-cta-cursor-wrap {
    display: flex;
    align-self: stretch;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.25);
    width: 4rem
}

.docs-article__code-cta-cursor::before {
    content: '';
    border: solid white;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 4px;
    transform: rotate(-45deg) translateY(-1px);
    transition: transform 200ms cubic-bezier(0, 0, 0.2, 1)
}

.docs-article__code-cta-cursor::after {
    content: '';
    display: inline-block;
    background-color: white;
    height: 2px;
    width: 10px;
    margin-left: 0.25em;
    animation: blink 1.1s infinite;
    transition: transform 200ms cubic-bezier(0, 0, 0.2, 1)
}

.cursor-hover:hover .docs-article__code-cta-cursor::before {
    transform: rotate(-45deg) translateY(8px) translateX(8px)
}

.cursor-hover:hover .docs-article__code-cta-cursor::after {
    transform: translateX(-6px) translateY(-4px) scaleX(1.5);
    animation: none
}

@media screen and (max-width: 899px) {
    .docs-article__code-cta-cursor::before {
        transform: rotate(-45deg) translateY(8px) translateX(8px)
    }

    .docs-article__code-cta-cursor::after {
        transform: translateX(-6px) translateY(-4px) scaleX(1.5);
        animation: none
    }
}

.docs-article__code-cta-body {
    padding: 2rem
}

.docs-article__code-cta-title {
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.55;
    -webkit-font-smoothing: antialiased
}

.docs-article__code-cta-path {
    margin-top: 0.5rem;
    display: inline-block;
    padding: 0.25rem 1rem;
    font-size: 0.75rem;
    line-height: 1.5;
    color: #94979B;
    color: white;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 4px
}

.docs-article__code-cta-arrow {
    content: '';
    position: absolute;
    right: 0rem;
    background: white;
    border-top: 50px solid transparent;
    border-left: 1rem solid #008CFF;
    border-bottom: 50px solid transparent;
    z-index: 10
}

.docs-api-table tr {
    display: flex;
    flex-direction: column;
    flex: 1
}

.docs-api-table td {
    padding: 2rem
}

.docs-api-table td:not(:last-child) {
    padding-bottom: 0
}

.docs-api-table__title:not(:first-child) {
    display: none
}

@media screen and (max-width: 479px) {
    .docs-api-table__param {
        padding-bottom: 0
    }
}

code.docs-api-param-name {
    color: white;
    background-color: #233659;
    display: inline-block;
    padding: 0.2rem 0.6rem;
    border-radius: 4px;
    margin-bottom: 0.5rem
}

.docs-api-param-label {
    font-size: 0.75rem;
    line-height: 1.5;
    color: #94979B;
    font-style: italic
}

.docs-api-tags {
    font-size: 0;
    list-style: none;
    margin-bottom: 1rem
}

.docs-api-tags li {
    display: inline-block
}

.docs-api-tag {
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-size: 0.625rem;
    font-weight: 500;
    line-height: 1;
    display: inline-block;
    padding: 0.75em 1em;
    margin-right: 0.75em;
    margin-bottom: 0.75em;
    box-shadow: inset 0 0 0 1px rgba(148, 151, 155, 0.2);
    border-radius: 2px;
    transition: background-color 100ms
}

.docs-api-tag:hover {
    background: white
}

.docs-api-tag.is-selected {
    color: white;
    background-color: #008CFF;
    box-shadow: none;
    animation: pulseBlue 500ms
}

.docs-api-tag--post {
    font-weight: 600;
    color: #36D576
}

.docs-api-tag--type {
    font-style: italic
}

.docs-api-description:not(:last-child) {
    margin-bottom: 1rem
}

div.docs-api-action {
    border: 1px solid rgba(148, 151, 155, 0.2);
    padding: 0.5rem;
    overflow: auto
}

div.docs-api-action > code.docs-api-action--url {
    font-size: 0.8rem
}

.docs-coderail {
    color: white;
    background-color: #233659;
    padding-bottom: 10rem;
    border-radius: 0
}

@media screen and (max-width: 1199px) {
    .docs-coderail.docs-layout-column {
        display: none
    }
}

.docs-coderail .twlo-code__actions {
    margin-top: 0.3rem
}

.docs-coderail .twlo-code__actions .twlo-code__outdated {
    background-color: rgba(242, 47, 70, 0.8);
    line-height: 2rem;
    cursor: default
}

.docs-coderail .twlo-code__snippet {
    padding-top: 2.5rem
}

.docs-coderail .twlo-code {
    overflow: auto
}

.docs-coderail__next, .docs-coderail__show_output {
    position: sticky;
    left: 0;
    text-align: right;
    margin-right: 1rem;
    margin-bottom: 1rem;
    user-select: none
}

.docs-coderail__next-link {
    position: relative;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-size: 0.625rem;
    font-weight: 500;
    line-height: 1;
    background: rgba(255, 255, 255, 0.15);
    transition: background 100ms;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 1.5rem;
    width: 6.5rem;
    margin-right: 0.5rem
}

.docs-coderail__next-link::after {
    content: '';
    position: absolute;
    right: -0.5rem;
    top: 0;
    border-top: 0.75rem solid transparent;
    border-left: 0.5rem solid rgba(255, 255, 255, 0.15);
    border-bottom: 0.75rem solid transparent;
    transition: border-left-color 100ms
}

.docs-coderail__show_output-link {
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-size: 0.625rem;
    font-weight: 500;
    line-height: 1;
    color: white;
    opacity: 0.5
}

.docs-coderail__show_output-link:hover {
    opacity: 1
}

.docs-coderail__next-link:hover {
    background-color: #008CFF
}

.docs-coderail__next-link:hover::after {
    border-left-color: #008CFF
}

.docs-coderail__label {
    position: sticky;
    left: 0;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-size: 0.625rem;
    font-weight: 500;
    line-height: 1;
    color: #94979B;
    text-align: right;
    margin-top: 1rem;
    margin-right: 1rem
}

@media screen and (min-width: 1400px) {
    .docs-coderail__label {
        margin-bottom: -1.5rem
    }
}

.code-sample .inline-mode.forced-inline {
    display: block
}

.code-sample .button-mode.forced-inline {
    display: none
}

@media screen and (min-width: 1200px) {
    .code-sample .inline-mode {
        display: none
    }

    .code-sample .button-mode {
        display: block
    }
}

@media screen and (max-width: 1199px) {
    .code-sample .inline-mode {
        display: block
    }

    .code-sample .button-mode {
        display: none
    }
}

.code-sample:not(.active) .docs-article__code-cta-arrow {
    display: none
}

.twlo-code-header__actions li {
    cursor: pointer
}

.docs-feedback {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0.5rem;
    z-index: 10
}

@media screen and (min-width: 900px) {
    .docs-feedback {
        top: 0
    }
}

.docs-feedback .feedback-submit-btn {
    align-self: center;
    margin-bottom: 2rem
}

.docs-feedback .feedback-footer {
    align-self: center;
    font-size: 0.8rem
}

.docs-feedback .feedback-footer a {
    color: #008CFF;
    opacity: 0.999;
    text-decoration: underline
}

.docs-feedback .feedback-footer a:hover, .docs-feedback .feedback-footer a:visited {
    color: #0062b3
}

.docs-feedback .feedback-footer a > svg {
    fill: currentColor
}

@media screen and (min-width: 900px) {
    .docs-product-landing-page .docs-feedback {
        position: absolute;
        right: 4rem
    }
}

@media screen and (max-width: 899px) {
    .docs-product-landing-page .docs-feedback {
        position: relative;
        top: -3rem
    }
}

.docs-feedback.is-open {
    z-index: 30
}

.docs-feedback__rating-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 225px;
    margin-top: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
    background: white;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0 4px 24px 0 rgba(86, 91, 115, 0.15);
    padding: 1rem;
    font-size: 0.75rem;
    line-height: 1.5;
    color: #94979B
}

@media screen and (min-width: 900px) {
    .docs-feedback__rating-card {
        align-self: stretch
    }
}

.docs-feedback__rating-label {
    margin-right: 0.5rem;
    user-select: none
}

.docs-feedback__rating-stars {
    display: flex;
    list-style: none
}

.docs-feedback__rating-star {
    line-height: 0;
    cursor: pointer
}

.docs-feedback__rating-star:not(:last-child) {
    margin-right: 0.25rem
}

.docs-feedback__rating-star::before {
    content: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%3E%3Cpath%20fill%3D%22%23E8E8E8%22%20d%3D%22M8%200l2%206h6l-5%204%202%206-5-4-5%204%202-6-5-4h6l2-6z%22%2F%3E%3C%2Fsvg%3E")
}

.docs-feedback__rating-star:hover::before, .docs-feedback__rating-star.is-selected::before {
    content: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%3E%3Cpath%20fill%3D%22%23EFDC2E%22%20d%3D%22M8%200l2%206h6l-5%204%202%206-5-4-5%204%202-6-5-4h6l2-6z%22%2F%3E%3C%2Fsvg%3E")
}

.docs-modal-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 30;
    display: none;
    align-items: center;
    justify-content: center;
    background: rgba(35, 54, 89, 0.9)
}

.docs-modal-overlay.is-open {
    display: flex
}

.docs-modal {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 3rem;
    margin: 1rem;
    width: 100%;
    max-width: 600px;
    min-height: 500px;
    background: white;
    border-radius: 4px;
    box-shadow: 0 30px 60px rgba(21, 39, 72, 0.5);
    animation: fadeInUp 300ms cubic-bezier(0, 0, 0.2, 1)
}

@media screen and (max-width: 479px) {
    .docs-modal {
        padding: 1rem
    }
}

.docs-modal__title {
    font-size: 1.8rem;
    line-height: 1.35;
    margin-bottom: 1rem
}

.docs-modal__body {
    font-size: 1rem;
    line-height: 1.65;
    margin-bottom: 2rem
}

.docs-modal__textarea {
    height: 8rem;
    margin-bottom: 2rem
}

.docs-modal__close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 1rem;
    cursor: pointer
}

.docs-modal__submit-message {
    font-size: 2.2rem;
    line-height: 1.25;
    color: white;
    animation: fadeInUp 300ms cubic-bezier(0, 0, 0.2, 1)
}

.docs-related-product {
    list-style: none;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    background: white;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0 4px 24px 0 rgba(86, 91, 115, 0.15);
    transition-property: all;
    transition-duration: 200ms
}

@media screen and (min-width: 900px) {
    .docs-related-product {
        align-self: stretch
    }
}

.docs-related-product:hover {
    transform: translateY(-2px);
    box-shadow: 0 16px 60px 0 rgba(86, 91, 115, 0.2)
}

.docs-related-product:active {
    transition: none;
    transform: translateY(-1px)
}

@media screen and (max-width: 899px) {
    .docs-related-product {
        margin-bottom: 1.5rem
    }
}

.docs-related-product-label {
    font-size: 0.75rem;
    line-height: 1.7;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    color: #94979B;
    color: rgba(140, 91, 216, 0.8);
    background: rgba(140, 91, 216, 0.15);
    line-height: 0;
    padding: 0.75em 1em;
    border-radius: 2px;
    white-space: nowrap;
    transition: background 200ms;
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    pointer-events: none;
    opacity: 0;
    transition: opacity 200ms
}

.docs-related-product-label svg, .docs-related-product-label::after {
    fill: currentColor;
    margin-left: 0.5rem
}

.docs-related-product-label::after {
    content: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%228%22%20height%3D%228%22%20viewBox%3D%220%200%208%208%22%3E%3Cpath%20fill%3D%22rgba%28140%2C91%2C216%2C0.8%29%22%20d%3D%22M8%208H7V1.7L.9%207.9l-.8-.8L6.3%201H0V0h8v8z%22%2F%3E%3C%2Fsvg%3E")
}

.docs-related-product-label:hover {
    background: rgba(140, 91, 216, 0.25)
}

.docs-related-product:hover .docs-related-product-label {
    opacity: 1
}

.docs-related-product__inner {
    display: flex;
    align-items: flex-start;
    padding: 2rem
}

.docs-related-product__icon {
    height: 4rem;
    width: 4rem;
    border-radius: 100%;
    display: flex;
    flex: none;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
    color: #8C5BD8;
    background: rgba(140, 91, 216, 0.05)
}

.docs-related-product__icon path {
    fill: currentColor
}

.docs-related-product__title {
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.55
}

.docs-related-product__subtitle {
    font-size: 0.9rem;
    line-height: 1.6;
    color: #94979B
}

.docs-results {
    list-style: none;
    padding-bottom: 10rem
}

.docs-result {
    padding: 2rem 3rem;
    border-bottom: 1px solid rgba(148, 151, 155, 0.2);
    animation: fadeInUp 400ms backwards
}

.docs-result:nth-of-type(1) {
    animation-delay: 100ms
}

.docs-result:nth-of-type(2) {
    animation-delay: 150ms
}

.docs-result:nth-of-type(3) {
    animation-delay: 200ms
}

.docs-result:nth-of-type(4) {
    animation-delay: 250ms
}

.docs-result:nth-of-type(5) {
    animation-delay: 300ms
}

.docs-result:nth-of-type(6) {
    animation-delay: 350ms
}

.docs-result:nth-of-type(7) {
    animation-delay: 400ms
}

.docs-result:nth-of-type(8) {
    animation-delay: 450ms
}

.docs-result:nth-of-type(9) {
    animation-delay: 500ms
}

.docs-result:nth-of-type(10) {
    animation-delay: 550ms
}

.docs-result:nth-of-type(11) {
    animation-delay: 600ms
}

.docs-result:nth-of-type(12) {
    animation-delay: 650ms
}

.docs-result:nth-of-type(13) {
    animation-delay: 700ms
}

.docs-result:nth-of-type(14) {
    animation-delay: 750ms
}

.docs-result:nth-of-type(15) {
    animation-delay: 800ms
}

.docs-result:nth-of-type(16) {
    animation-delay: 850ms
}

.docs-result:nth-of-type(17) {
    animation-delay: 900ms
}

.docs-result:nth-of-type(18) {
    animation-delay: 950ms
}

.docs-result:nth-of-type(19) {
    animation-delay: 1000ms
}

@media screen and (max-width: 899px) {
    .docs-result {
        padding: 1.5rem
    }
}

.docs-result__title {
    font-size: 1.4rem;
    line-height: 1.45;
    font-weight: 500;
    margin-bottom: 1rem
}

.docs-result__subtitle {
    font-size: 1rem;
    line-height: 1.65;
    max-width: 95ch;
    margin-bottom: 3rem
}

.docs-result__links {
    font-size: 0;
    list-style: none
}

.docs-result__links li {
    display: inline-block
}

.docs-results-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100% - 150px);
    padding: 1rem;
    animation: fadeInUp 200ms cubic-bezier(0, 0, 0.2, 1)
}

.docs-results-message__icon {
    height: 5rem;
    width: 5rem;
    margin-bottom: 1rem;
    color: #E8E8E8
}

.docs-results-message__title {
    font-size: 1rem;
    line-height: 1.65;
    color: #94979B;
    text-align: center
}

body.smartling-ja a[href='/docs/search'] {
    display: none
}

.docs-search {
    background: #152748;
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 3rem
}

@media screen and (max-width: 899px) {
    .docs-search {
        padding: 1rem
    }
}

.docs-search__input {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: none;
    outline: none;
    font-size: inherit;
    font-family: inherit;
    padding: 1rem;
    height: 50px;
    width: 100%;
    border-radius: 4px;
    background-color: #F9F9F9;
    transition: box-shadow 200ms;
    padding: 0;
    font-size: 3rem;
    height: 4rem;
    color: white;
    background: transparent;
    caret-color: #008CFF
}

.docs-search__input:disabled {
    opacity: 0.5;
    cursor: not-allowed
}

.docs-search__input::placeholder {
    color: #94979B
}

.docs-search__input::placeholder {
    color: #565B73
}

.docs-search__input::-webkit-search-cancel-button {
    appearance: none;
    -webkit-appearance: none;
    content: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%3E%3Cpath%20fill%3D%22%23fff%22%20d%3D%22M15%204l-5%205%205%205v1h-1l-5-5-5%205H3v-1l5-5-5-5V3h1l5%205%205-5h1v1z%22%2F%3E%3C%2Fsvg%3E");
    background-color: rgba(255, 255, 255, 0.1);
    padding: 1rem;
    margin-left: 1rem;
    border-radius: 100%;
    transition: background 100ms
}

@media screen and (max-width: 899px) {
    .docs-search__input {
        font-size: 2rem
    }
}

.docs-help {
    background-color: rgba(0, 140, 255, 0.05);
    border: 1px solid rgba(0, 140, 255, 0.1);
    padding: 2rem;
    border-radius: 4px
}

.docs-help__title {
    font-size: 1.4rem;
    line-height: 1.45;
    font-weight: 500;
    margin-bottom: 1rem
}

.docs-help__title::before {
    content: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%3E%3Cpath%20fill%3D%22%23008CFF%22%20d%3D%22M8%200C3.6%200%200%203.6%200%208s3.6%208%208%208%208-3.6%208-8-3.6-8-8-8zm6.7%206h-3.3c-.3-.6-.8-1.1-1.4-1.4V1.3c2.3.7%204%202.4%204.7%204.7zM8%2011c-1.7%200-3-1.3-3-3s1.3-3%203-3%203%201.3%203%203-1.3%203-3%203zm1-9.9v3.1c-.3-.1-.7-.2-1-.2s-.7.1-1%20.1v-3c.3-.1.7-.1%201-.1s.7%200%201%20.1zm-3%20.2v3.3c-.6.3-1.1.8-1.4%201.4H1.3C2%203.7%203.7%202%206%201.3zM1.1%207h3.1c-.1.3-.2.7-.2%201s.1.7.1%201h-3C1%208.7%201%208.3%201%208s0-.7.1-1zm.2%203h3.3c.3.6.8%201.1%201.4%201.4v3.3C3.7%2014%202%2012.3%201.3%2010zM7%2014.9v-3.1c.3.1.7.2%201%20.2s.7-.1%201-.1V15H8c-.3%200-.7%200-1-.1zm3-.2v-3.3c.6-.3%201.1-.8%201.4-1.4h3.3c-.7%202.3-2.4%204-4.7%204.7zM14.9%209h-3.1c.1-.3.2-.7.2-1s-.1-.7-.1-1H15v1c0%20.3%200%20.7-.1%201z%22%2F%3E%3C%2Fsvg%3E");
    margin-right: 0.5em
}

.docs-help__body {
    font-size: 1rem;
    line-height: 1.65
}

.docs-article-footer__list {
    list-style: none;
    font-size: 0.8rem;
    display: flex;
    justify-content: space-around;
    align-items: center
}

@media screen and (max-width: 479px) {
    .docs-article-footer__list {
        flex-direction: column
    }
}

.docs-article-footer__list-item {
    white-space: nowrap
}

.docs-article-footer__list-item:not(:last-child) {
    margin-right: 1rem
}

.docs-tutorial-header__by-line, .docs-tutorial-header__contributors {
    font-size: 0.75rem;
    line-height: 1.5;
    color: #94979B;
    font-style: italic
}

.docs-tutorial-header__by-line img, .docs-tutorial-header__contributors img {
    width: 25px;
    height: 25px;
    border-radius: 100%;
    display: inline
}

.docs-tutorial-header__by-line label, .docs-tutorial-header__by-line img, .docs-tutorial-header__contributors label, .docs-tutorial-header__contributors img {
    margin-right: 5px
}

.docs-tutorial-header__author, .docs-tutorial-header__contributors {
    display: flex;
    align-items: center
}

.docs-tutorial-header__buttons {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap
}

.docs-tutorial-header__buttons select {
    width: auto
}

.docs-tutorial-header__buttons select:hover {
    transform: none
}

div.lightbox-img-wrapper {
    position: relative
}

div.lightbox-img-wrapper:hover img {
    opacity: 0.3
}

div.lightbox-img-wrapper:hover .lightbox-img-wrapper-overlay {
    opacity: 1
}

div.lightbox-img-wrapper img {
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    transition: .5s ease;
    backface-visibility: hidden
}

div.lightbox-img-wrapper .lightbox-img-wrapper-overlay {
    transition: .5s ease;
    opacity: 0;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%)
}

div.lightbox-img-wrapper .lightbox-img-wrapper-overlay i.fa {
    font-size: 68px;
    color: gray
}

.codehilite .c {
    color: #94979B
}

.codehilite .err {
    color: #fff
}

.codehilite .g {
    color: #fff
}

.codehilite .k {
    color: #BDDCDC
}

.codehilite .l {
    color: #fff
}

.codehilite .n {
    color: #fff
}

.codehilite .o {
    color: #fff
}

.codehilite .x {
    color: #B4D388
}

.codehilite .p {
    color: #fff
}

.codehilite .cm {
    color: #94979B
}

.codehilite .cp {
    color: #CF5340
}

.codehilite .c1 {
    color: #94979B
}

.codehilite .cs {
    color: #94979B
}

.codehilite .gd {
    color: #BDDCDC
}

.codehilite .ge {
    color: #fff;
    font-style: italic
}

.codehilite .gr {
    color: #dc322f
}

.codehilite .gh {
    color: #B4D388
}

.codehilite .gi {
    color: #fff
}

.codehilite .go {
    color: #fff
}

.codehilite .gp {
    color: #fff
}

.codehilite .gs {
    color: #fff;
    font-weight: bold
}

.codehilite .gu {
    color: #B4D388
}

.codehilite .gt {
    color: #fff
}

.codehilite .kc {
    color: #B4D388
}

.codehilite .kd {
    color: #92BFBF
}

.codehilite .kn {
    color: #92BFBF
}

.codehilite .kp {
    color: #92BFBF
}

.codehilite .kr {
    color: #92BFBF
}

.codehilite .kt {
    color: #92BFBF
}

.codehilite .ld {
    color: #fff
}

.codehilite .m {
    color: #BDDCDC
}

.codehilite .s {
    color: #BDDCDC
}

.codehilite .na {
    color: #EA7956
}

.codehilite .nb {
    color: #F9ED7B
}

.codehilite .nc {
    color: #EA7956
}

.codehilite .no {
    color: #B4D388
}

.codehilite .nd {
    color: #EA7956
}

.codehilite .ni {
    color: #B4D388
}

.codehilite .ne {
    color: #B4D388
}

.codehilite .nf {
    color: #EA7956
}

.codehilite .nl {
    color: #fff
}

.codehilite .nn {
    color: #fff
}

.codehilite .nx {
    color: #EA7956
}

.codehilite .py {
    color: #fff
}

.codehilite .nt {
    color: #BDDCDC
}

.codehilite .nv {
    color: #EA7956
}

.codehilite .ow {
    color: #fff
}

.codehilite .w {
    color: #fff
}

.codehilite .mf {
    color: #BDDCDC
}

.codehilite .mh {
    color: #BDDCDC
}

.codehilite .mi {
    color: #BDDCDC
}

.codehilite .mo {
    color: #BDDCDC
}

.codehilite .sb {
    color: #93a1a1
}

.codehilite .sc {
    color: #BDDCDC
}

.codehilite .sd {
    color: #fff
}

.codehilite .s2 {
    color: #fff
}

.codehilite .se {
    color: #B4D388
}

.codehilite .sh {
    color: #fff
}

.codehilite .si {
    color: #BDDCDC
}

.codehilite .sx {
    color: #BDDCDC
}

.codehilite .sr {
    color: #dc322f
}

.codehilite .s1 {
    color: #BDDCDC
}

.codehilite .ss {
    color: #BDDCDC
}

.codehilite .bp {
    color: #EA7956
}

.codehilite .vc {
    color: #EA7956
}

.codehilite .vg {
    color: #EA7956
}

.codehilite .vi {
    color: #EA7956
}

.codehilite .il {
    color: #BDDCDC
}

.codehilite.language-py .kn {
    color: #EA7956
}

.codehilite.language-py .k {
    color: #B4D388
}

.codehilite.language-py .s2 {
    color: #BDDCDC
}

.codehilite.language-js .kr {
    color: #F9ED7B
}

.codehilite.language-js .nx {
    color: #fff
}

.codehilite .code-line.fr.fr-on > span {
    color: rgba(148, 151, 155, 0.2) !important;
    text-shadow: 0 0 3px #94979B
}

.code-grey {
    color: #b7b9bc
}

.code-blue {
    color: #92BFBF
}

.code-paleblue {
    color: #BDDCDC
}

.code-green {
    color: #B4D388
}

.code-yellow {
    color: #F9ED7B
}

.code-dark-yellow {
    color: #e4d121
}

.code-orange {
    color: #EA7956
}

.code-red {
    color: #CF5340
}

.hljs-comment, .hljs-symbol, .hljs-bullet, .hljs-addition {
    color: #b7b9bc
}

.hljs-variable, .hljs-template-variable, .hljs-name, .hljs-selector-id, .hljs-selector-class, .hljs-regexp, .hljs-deletion {
    color: #CF5340
}

.hljs-attribute, .hljs-attr, .hljs-literal, .hljs-type, .hljs-meta, .hljs-params, .hljs-link {
    color: #B4D388
}

.hljs-title, .hljs-section, .hljs-tag, .hljs-quote, .hljs-string {
    color: #92BFBF
}

.hljs-number, .hljs-built_in, .hljs-builtin-name, .hljs-keyword, .hljs-selector-tag {
    color: #EA7956
}

.twlo-code__sample, .twlo-code__output {
    z-index: 1;
}
.code-line{
    display: block;
}
.indent_20{
    text-indent: 20px;
}
.indent_40{
    text-indent: 40px;
}
.indent_60{
    text-indent: 60px;
}
.indent_80{
    text-indent: 80px;

}